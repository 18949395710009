import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Base64 } from "js-base64";
// import createFile from 'create-file';
import PDFMerger from 'pdf-merger-js/browser';
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { dateFormate } from "../../utils/formatTime";
import SendOtp from "./SendOtp"
import AlertMessages from "../../utils/AlertMessages";
import Loder from "../../utils/Loder";
import {
  getUsersById,
  AdharAndPanVerifigation,
  PostTransectionHistory, panVerifigation, SendOtpToClient, SaveOtp, SignDocWithNotRegistredNo,
  SignDocWithNotRegistred,
  SaveOtpVerifyMobileNo, verifyAdharWithSubmitOtp, SaveKycDoc, verifyAdharWithOtp
} from "../../services";


// designed
// const createFile = require('create-file');



function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>

        </Box>
      )}
    </div>
  );
}


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function BasicTabs() {
  const [isDisabled, disableButtons] = React.useState(true);
  const [Tab2Visible] = useState(false);

  const [values, setValues] = React.useState(0);

  //  by  location 
  const location = useLocation();
  const navigate = useNavigate();
  const userid = location.pathname.split("/")[2];






  // console.log("location", JSON.parse(Base64.decode(userid)));

  const adminId = JSON.parse(Base64.decode(userid)).admin_id
  const clientId = JSON.parse(Base64.decode(userid)).user_id

  // pdf merger 
  const [mergedPdfUrl, setMergedPdfUrl] = useState();
  const [abc, setAbc] = useState('');

  //  For Adhar verification 
  const [adharPan, setAdharPan] = useState("");
  const [dataClient, setDataClient] = useState("")
  const [aadharErr, setAadharErr] = useState("");
  const [PanErr, setPanErr] = useState("");
  const [buttondDissabled, setButtonDissabled] = useState(false);
  const [loderShow, setLoderShow] = useState(false)
  const [refresh, setrefresh] = useState(true)

  // For Adhar Verify Wit otp  

  const [AdharVerify, setAdharVerify] = useState('')
  const [AdharVerifyErr, setAdharVerifyErr] = useState('')
  const [submitAadharOtp, setsubmitAadharOtp] = useState('')
  const [showOtpResults, setShowOtpResults] = useState(false)
  const [submitAadharOtpErr, setsubmitAadharOtpErr] = useState('')
  const [adharClientID, setadharClientId] = useState('')
  const [adharButtonDissabled, setAdharButtonDissabled] = useState(false)


  const [seconds, setSeconds] = React.useState(60);


  const [panNo, setPanNo] = useState("");

  //   For Send Otp 

  const [otpReceiveErr, setOtpReceiveErr] = useState("");
  const [otpReceive, setOtpReceive] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [mobileNoErr, setMobileNoErr] = useState("");
  const [clientOtp, setClientOtp] = useState("");
  const [showResults, setShowResults] = useState(false)
  const [otpverify, setOtpverify] = useState(false)

  //  For Esign

  const [value, setValue] = React.useState(0);

  const [alertMsg, setAlertMsg] = useState(false);
  const [alertColor, setAlertColor] = useState("");
  const [textAlert, setTextAlert] = useState("");

  //    set Adhar Info
  const [adharPanInfo, setAdharPanInfo] = useState("");
  const [PanInfo, setPanInfo] = useState("");

  // kyc State 

  const [KYC, setKYC] = useState("");


  // other
  const [pdfInfo, setPdfInfo] = useState([]);

  // console.log("adharPanInfo", adharPanInfo);
  const hideClose = () => {
    setAlertMsg(false);
  };


  const handleChange = (event, newValue1) => {


    setValues(newValue1);
  };

  // For E-sign Tab Change
  const handleEsignChange = (event, newValue) => {
    setValue(newValue);
  };



  // main Compoent


  // Get Client Data By Client Id

  const getParticularClient = async () => {
    const response = await getUsersById(clientId);
    if (response) {
      setAbc(response.data.blobdocument.data)
      setDataClient(response.data);
    }
  };






  // timer
  useEffect(async () => {

    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(0);
    }

    //  stop Browser reload 

    // if (window.performance) {
    //   if (performance.navigation.type === 1) {
    //     // window.confirm( "Please Complete Your Verfication Without Refresh Browser" );
    //     window.confirm("IF You Want To Continue Verification Please Click On Cancel");
    //   }
    //   else {
    //     alert("Don't Refesh the Browser Until Your Process Not Complete");
    //   }
    // }

    // window.onbeforeunload = function () {
    //   return " hello world";
    //   // return window.confirm("You l go to default screen");
    // }

    // disabledButton()
    //  -----------------------------





  }, []);

  // const close = () => {
  //   e = e || window.event;
  //   if (e.keyCode == 116) {
  //     e.preventDefault();
  //   }
  // }

  useEffect(() => {
    getParticularClient();
    // close()
    // window.stop()
  }, [refresh]);


  // Adhar Verify With Otp



  const submitAdharVarifyByOtpButton = async (e) => {
    e.preventDefault();
    if (AdharVerify === "") {
      setAdharVerifyErr(" Please Enter  Your Aadhar No.")
      return
    }
    if (AdharVerify.length !== 12) {
      setAdharVerifyErr(" please Enter Valid Aadhar  No.")
      return
    }
    e.preventDefault();
    const data = {
      'id_number': AdharVerify,
      'less_info': "true"
    };
    const response = await verifyAdharWithOtp(data, setLoderShow(true));
    // console.log("dfsdffsdfsd", response.data)
    if (response.status_code === 200) {
      // console.log("response.status_code", response.status_code);
      setAdharButtonDissabled(true)
      setLoderShow(false)
      setadharClientId(response.data.client_id)
      setAlertMsg(true);
      setAlertColor("success");
      setTextAlert(response.message);
      setShowOtpResults(true)

    }
    else if (response.status === 422) {
      // console.log("response.message", response.message);
      setLoderShow(false)
      setAlertMsg(true);
      setAlertColor("error");
      setTextAlert(response.data.message);
    }
    else if (response.status_code === 500) {
      // console.log("response.message", response.message);
      setLoderShow(false)
      setAlertMsg(true);
      setAlertColor("error");
      setTextAlert(response.message);
    }

    else {
      // console.log("response.message" ,response.message);
      setLoderShow(false)
      setAlertMsg(true);
      setAlertColor("error");
      setTextAlert(response.message);
      // }

    }
  }

  //  adahr verify otp

  const VarifyAdharOtp = async (e) => {
    e.preventDefault();
    if (submitAadharOtp === "") {
      setsubmitAadharOtpErr(" *  Please Enter The Otp ")
      return
    }

    const data = {
      "client_id": adharClientID,
      "otp": submitAadharOtp
    }
    const response1 = await verifyAdharWithSubmitOtp(data, setLoderShow(true))
    // console.log("response1", response1);
    setAdharPanInfo(response1.data)
    if (response1.status_code === 200) {
      setLoderShow(false)
      setAlertMsg(true);
      setAlertColor("success");
      setTextAlert("Your Aadhaar  Verification is Successfully ");


      const transData = {
        'sign_type': "aadhar_verify_with_otp",
        'aadhaar_number': response1.data.aadhaar_number,
        'client_id': response1.data.client_id,
        'full_name': response1.data.full_name,
        'dob': response1.data.dob,
        'gender': response1.data.gender,
        'loc': response1.data.address.loc,
        'dist': response1.data.address.dist,
        'state': response1.data.address.state,
        'country': response1.data.address.country,
        'zip': response1.data.zip,
        'care_of': response1.data.care_of,
        'reference_id': response1.data.reference_id,
        'parent_admin_id': adminId,
        'user_id': clientId,

      };

      const res = await PostTransectionHistory(transData);

      // setrefresh(!refresh)

      setValues(values + 1)
      disableButtons(false);
    }
    else if (response1.status === 422) {
      setLoderShow(false)
      setAlertMsg(true);
      setAlertColor("error");
      setTextAlert(response1.data.message_code);
    }
    else if (response1.status_code === 500) {
      setLoderShow(false)
      setAlertMsg(true);
      setAlertColor("error");
      setTextAlert(response1.message);
    }

  }


  // Adhar Verification 

  const submitVarify = async (e) => {
    e.preventDefault();
    if (adharPan === "") {
      setAadharErr(" please Enter  Your Aadhar No.")
      return
    }

    if (adharPan.length !== 12) {
      setAadharErr(" please Enter Valid Aadhar  No.")
      return
    }


    const data = {
      'id_number': adharPan,
      'less_info': "true"
    };

    const response = await AdharAndPanVerifigation(data, setLoderShow(true));
    // setAdharPanInfo(response.data)
    if (response.success === true) {
      setLoderShow(false)
      const transData = {
        'aadhaar_number': response.data.aadhaar_number,
        'full_name': response.data.full_name,
        'client_id': response.data.client_id,
        'age_range': response.data.age_range,
        'gender': response.data.gender,
        'last_digits': response.data.last_digits,
        'state': response.data.state,
        'sign_type': "aadhar_verify",
        'parent_admin_id': adminId,
        'user_id': clientId,
      };

      const res = await PostTransectionHistory(transData);
      setAlertMsg(true);
      setAlertColor("success");
      setTextAlert("Your Adhar  Verification is Successfully ");
      // setrefresh(!refresh)
      setValues(values + 1)
      disableButtons(false);


    } else if (response.data.success === false) {
      const transData = {
        'aadhaar_number': response.data.data.aadhaar_number,
        'client_id': response.data.data.client_id,
        'age_range': "failed",
        'gender': "failed",
        'last_digits': '000',
        'state': "failed",
        'sign_type': "aadhar_verify",
        'parent_admin_id': adminId,
        'user_id': clientId,
      };
      const res = await PostTransectionHistory(transData);
      setLoderShow(false)
      setTextAlert(response.data.message);
      setAlertMsg(true);
      setAlertColor("error");
    }
  };


  //  For Pan Verfication 

  const submitPanVarify = async (e) => {
    e.preventDefault();
    if (panNo === "") {
      setPanErr('Enter A Valid Pan No')
      return
    }

    const data = {
      'id_number': panNo,
    };
    const response = await panVerifigation(data, setLoderShow(true));
    setPanInfo(response.data)
    if (response.success === true) {
      setLoderShow(false)
      const transData = {
        'client_id': response.data.client_id,
        'pan_number': response.data.pan_number,
        'full_name': response.data.full_name,
        'category': response.data.category,
        'sign_type': "pan_verify",
        'parent_admin_id': adminId,
        'user_id': clientId,
      };
      const res = await PostTransectionHistory(transData);
      if (res) {
        setAlertMsg(true);
        setAlertColor("success");
        setTextAlert("Your Pancard Verification is Successfully ");
        // setrefresh(!refresh)
        setValues(values + 1)

        // disableButtons(!isDisabled);
      }

    } else if (response.data.success === false) {

      const transData = {
        'client_id': response.data.data.client_id,
        'pan_number': response.data.data.pan_number,
        'full_name': "failed",
        'category': "failed",
        'sign_type': "pan_verify",
        'parent_admin_id': adminId,
        'user_id': clientId,
      };
      const res = await PostTransectionHistory(transData);
      setLoderShow(false)
      setTextAlert(response.data.message);
      setAlertMsg(true);
      setAlertColor("error");
    }
  };


  //  Send Otp To Client 

  const submitOtpVarify = async (e) => {
    e.preventDefault();
    if (mobileNo === "") {
      setMobileNoErr(" Enter A Valid No")
      return
    }
    if (mobileNo.length !== 10) {
      setMobileNoErr("mobile No. Length Should Be 10 Digits Only")
      return
    }
    const digits = '0123456789';
    let OTP = '';
    for (let i = 0; i < 6; i += 1) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }


    const sendotp = {
      "mobileno": mobileNo,
      "otp": OTP
    }



    const response1 = await SendOtpToClient(sendotp, setLoderShow(true))
    if (response1.status === 'OK') {
      setLoderShow(false)

      const data = {
        "id": clientId,
        "otp": OTP
      }

      const resp = await SaveOtp(data)

      const request = {
        "id": clientId,
        "alternate_number": mobileNo
      }

      const response2 = await SaveOtpVerifyMobileNo(request)



      setAlertMsg(true);
      setAlertColor("success");
      setTextAlert("OTP Send Successfully  ");
      setButtonDissabled(true)
      setShowResults(true)
      const response = await getUsersById(clientId);
      // console.log("getUsersById", response);
      if (response) {
        setClientOtp(response.data.otp);
      }

    }

  };

  // console.log("clientOtp", clientOtp);

  const VarifyOtp = (e) => {
    e.preventDefault();

    if (otpReceive === "") {
      setOtpReceiveErr(" *  Please Enter The OTP ")
      return
    }

    if (otpReceive === clientOtp) {
      setLoderShow(false)
      setOtpverify(true)
      setAlertMsg(true);
      setAlertColor("success");
      setTextAlert("OTP Verification  Successully");
      setOtpReceive("")
      // disableButtons(!isDisabled);
      // setrefresh(!refresh)
      setValues(values + 1)

      // setButtonDissabled(false)

    }
    else {
      setLoderShow(false)
      setOtpverify(false)
      setAlertMsg(true);
      setAlertColor("warning");
      setTextAlert("Please  Enter Valid  OTP ");
    }

  }
  // Resend Otp

  const ResendOtp = async (e) => {

    // setButtonDissabled(true)
    e.preventDefault();
    if (mobileNo === "") {
      setMobileNoErr(" Enter A Valid Contact No")
      return
    }
    if (mobileNo.length !== 10) {
      setMobileNoErr(" mobile No. Length Should Be 10 Digits Only")
      return
    }


    const digits = '0123456789';
    let OTP = '';
    for (let i = 0; i < 6; i += 1) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }

    const sendotp = {
      "mobileno": mobileNo,
      "otp": OTP
    }
    const response1 = await SendOtpToClient(sendotp)
    // console.log("response1" ,response1);

    if (response1.status === 'OK') {
      const data = {
        "id": clientId,
        "otp": OTP
      }
      const response2 = await SaveOtp(data)
      // setMobileNo(" ")
      setLoderShow(false)
      setAlertMsg(true);
      setAlertColor("success");
      setTextAlert(" We Will Send You One Time Password(OTP) On Your Mobile No.. ");
      // setShowResults(true)

      const response = await getUsersById(clientId);
      // getUsersById("hello worldf" ,response)
      if (response) {
        setClientOtp(response.data.otp);

      }

    }


  }


  //  Esign Verification 

  const submitVarifydoc = async (e) => {

    e.preventDefault();
    setValue(value + 1)
  }

  const submitVarifyDocument = async (e) => {
    e.preventDefault();
    setLoderShow(true)

    if (dataClient.otpbased === 1) {
      const pdfDoc1 = await PDFDocument.create()
      // --------------------------------------------------------------------------

      // // Embed the Times Roman font
      const timesRomanFont = await pdfDoc1.embedFont(StandardFonts.TimesRoman)

      // // Add a blank page to the document
      const page1 = pdfDoc1.addPage()

      // // Get the width and height of the page
      const { width, height } = page1.getSize()

      // // Draw a string of text toward the top of the page
      const fontSize = 30
      page1.drawText('KYC Details  ', {
        x: 220,
        y: 800,
        // y: height - 4 * fontSize,
        size: fontSize,
        font: timesRomanFont,
        color: rgb(0, 0.53, 0.71),
      })


      page1.drawText('Pan  Details', {
        x: 25,
        y: 700,
        // y: height - 4 * fontSize,
        size: 20,
        font: timesRomanFont,
        color: rgb(0, 0.53, 0.71),
      })


      page1.drawText(`Pan No.                           -           ${PanInfo.pan_number} `, {
        x: 25,
        y: 670,
        // y: height - 4 * fontSize,
        size: 12,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
      })
      page1.drawText(`Name                              -            ${PanInfo.full_name}`, {
        x: 25,
        y: 650,
        // y: height - 4 * fontSize,
        size: 12,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
      })
      page1.drawText(`Catagory                         -            ${PanInfo.category} `, {
        x: 25,
        y: 630,
        // y: height - 4 * fontSize,
        size: 12,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
      })


      const KycDetailsPdf = await pdfDoc1.saveAsBase64({ dataUri: true })
      // --------------------------------------------------------------------------------
      const kycdata = {
        "id": clientId,
        'admin_id': adminId,
        'kycdocument': KycDetailsPdf
      }
      const kycresponse = await SaveKycDoc(kycdata);


      // --------------------------------------------------------------------------

      const req = {
        "id": clientId,

      }
      const respons11 = await SignDocWithNotRegistredNo(req);

      if (respons11) {
        setAlertMsg(true);
        setAlertColor("success");
        setTextAlert(respons11.message);
      }
 
      const respons = await SignDocWithNotRegistred(req);
      
      const transData = {
        'client_id': dataClient.client_id,
        'parent_admin_id': adminId,
        'user_id': clientId,
        'sign_type': 'aadhaar_sign'
      };
      const respo = await PostTransectionHistory(transData)
      
      setLoderShow(false)

      const afterkycResponse = await getUsersById(clientId);
      navigate('/thankyou', { state: afterkycResponse }, { replace: true })
      // disableButtons(!isDisabled);
    }

    else {


      // Third party Request



      // ===============================

      setLoderShow(true)

      const pdfDoc1 = await PDFDocument.create()

      const timesRomanFont = await pdfDoc1.embedFont(StandardFonts.TimesRoman)

      // Add a blank page to the document
      const page1 = pdfDoc1.addPage()

      // Get the width and height of the page
      const { width, height } = page1.getSize()

      // Draw a string of text toward the top of the page

      const fontSize = 30
      page1.drawText('KYC Details  ', {
        x: 220,
        y: 800,
        // y: height - 4 * fontSize,
        size: fontSize,
        font: timesRomanFont,
        color: rgb(0, 0.53, 0.71),
      })

      page1.drawText('Aadhaar Details', {
        x: 25,
        y: 700,
        // y: height - 4 * fontSize,
        size: 20,
        font: timesRomanFont,
        color: rgb(0, 0.53, 0.71),
      })

      page1.drawText(`Aadhaar Number                    -                       ${adharPanInfo.aadhaar_number}`, {
        x: 25,
        y: 670,
        // y: height - 4 * fontSize,
        size: 12,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
      })
      page1.drawText(`Full Name                               -                       ${adharPanInfo.full_name} `, {
        x: 25,
        y: 650,
        // y: height - 4 * fontSize,
        size: 12,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
      })
      page1.drawText(`DOB                                         -                       ${(adharPanInfo.dob)}`, {
        x: 25,
        y: 630,
        // y: height - 4 * fontSize,
        size: 12,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
      })
      page1.drawText(`Gender                                    -                       ${adharPanInfo.gender}`, {
        x: 25,
        y: 610,
        // y: height - 4 * fontSize,
        size: 12,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
      })
      page1.drawText(`Loc                                          -                        ${adharPanInfo.address.loc} `, {
        x: 25,
        y: 590,
        // y: height - 4 * fontSize,
        size: 12,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
      })


      page1.drawText(`Dist                                         -                        ${adharPanInfo.address.dist} `, {
        x: 25,
        y: 570,
        // y: height - 4 * fontSize,
        size: 12,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
      })
      page1.drawText(`State                                       -                       ${adharPanInfo.address.state} `, {
        x: 25,
        y: 550,
        // y: height - 4 * fontSize,
        size: 12,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
      })
      page1.drawText(`Country                                   -                       ${adharPanInfo.address.country} `, {
        x: 25,
        y: 530,
        // y: height - 4 * fontSize,
        size: 12,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
      })
      page1.drawText(`Zip                                          -                        ${adharPanInfo.zip} `, {
        x: 25,
        y: 510,
        // y: height - 4 * fontSize,
        size: 12,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
      })
      page1.drawText(`Care Of                                    -                        ${adharPanInfo.care_of} `, {
        x: 25,
        y: 490,
        // y: height - 4 * fontSize,
        size: 12,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
      })
      page1.drawText(`Reference_Id                            -                       ${adharPanInfo.reference_id} `, {
        x: 25,
        y: 470,
        // y: height - 4 * fontSize,
        size: 12,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
      })


      page1.drawText('Pan  Details', {
        x: 25,
        y: 430,
        // y: height - 4 * fontSize,
        size: 20,
        font: timesRomanFont,
        color: rgb(0, 0.53, 0.71),
      })


      page1.drawText(`Pan No.                                    -                        ${PanInfo.pan_number} `, {
        x: 25,
        y: 410,
        // y: height - 4 * fontSize,
        size: 12,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
      })
      page1.drawText(`Name                                       -                        ${PanInfo.full_name}`, {
        x: 25,
        y: 390,
        // y: height - 4 * fontSize,
        size: 12,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
      })
      page1.drawText(`Catagory                                  -                       ${PanInfo.category} `, {
        x: 25,
        y: 370,
        // y: height - 4 * fontSize,
        size: 12,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
      })

      // const arr = []

      const pdfBytes1 = await pdfDoc1.saveAsBase64({ dataUri: true })

      const kycdata = {
        "id": clientId,
        'admin_id': adminId,
        'kycdocument': pdfBytes1
      }
      const response = await SaveKycDoc(kycdata);

      window.open(dataClient.data, '_blank')

      const transData = {
        'client_id': dataClient.client_id,
        'parent_admin_id': adminId,
        'user_id': clientId,
        'sign_type': 'aadhaar_sign'
      };
      const respo = await PostTransectionHistory(transData);

      navigate('/thankyou', { replace: true })


    }
  }



  return (
    <>
      <div style={{ overflowY: 'hidden', height: '100vh' }}>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-6 img-row d-none d-md-block'>
              <img src="../assets/images/vp.png" alt="..." />
            </div>
            <div className='col-md-6 pe-0'>
              <div className="form multistep-form">
                <div className="form-container  ">
                  <div className="form-header">
                    <p className='text-center form-heading'>Document Verification Panel</p>
                  </div>
                  <div className="form-body">
                    {/* <SubmitOtp /> */}

                    <Box sx={{ width: '100%' }}>
                      {loderShow ? <Loder /> : ''}
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={values} onChange={handleChange} aria-label="basic tabs example" centered>
                          {/* adhar  Verify */}
                          {
                            dataClient.adhaar === 1 || dataClient.adhaar_verify_with_otp === 1 ?
                              <Tab label="Aadhaar Verification"
                                 disabled
                                {...a11yProps(0)} />

                              : ""}
                          {/* Pan Verify */}
                          < Tab label="Pan Verification"
                             disabled
                            {...a11yProps(
                              1
                            )}
                            className={dataClient.pan === 0 ? "d-none" : " d-block"}
                          />
                          {/* otp Verify */}

                          {dataClient.otpbased === 1 ?
                            <Tab label="Verify By OTP"
                              // className={clientOtp.length < 6 ? "d-none" : " d-block"}
                               disabled
                              {...a11yProps(
                                2
                              )} />
                            : ""}

                          {/* E-sign Verify */}

                          {dataClient.adhaar_sign === 1 ?
                            <Tab label="E-sign Verification"
                              disabled
                              {...a11yProps(
                                3
                              )} />
                            : ''}
                        </Tabs>
                      </Box>

                      {/* Aadhar  Verify */}
                      <TabPanel value={values} index={
                        0
                      }  >
                        {
                          dataClient.adhaar === 1 && dataClient.adhaar_verify_with_otp === 0 ? <>
                            <div className="mb-3">
                              <input
                                onChange={(e) => {
                                  setAdharPan(e.target.value);
                                  setAadharErr("");
                                }}
                                type="number"
                                className="form-control"
                                placeholder="Enter Adhar No."
                              />
                            </div>

                            <p>{aadharErr}</p>

                            <button
                              type="submit"
                              onClick={(e) => submitVarify(e)}
                              className="default-button"
                            >
                              Verify & Next
                            </button>

                          </> :
                            ""
                        }

                        {/* Adhar Verify With Otp */}

                        {dataClient.adhaar === 0 && dataClient.adhaar_verify_with_otp === 1 ?
                          // <>
                          <form>
                            <div className="row d-flex align-items-center">
                              <div className="col-md-7">
                                <input
                                  onChange={(e) => {
                                    setAdharVerify(e.target.value);
                                    setAdharVerifyErr("");

                                  }}
                                  value={AdharVerify}
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter Valid Aadhaar  No."
                                // max="5"

                                />
                                {AdharVerifyErr ? <p>{AdharVerifyErr}</p> : ""}
                              </div>
                              <div className="col-md-4 float-lg-right">
                                <button
                                  type="submit"
                                  onClick={(e) => submitAdharVarifyByOtpButton(e)}
                                  className="default-button"
                                  disabled={adharButtonDissabled}
                                // loadingIndicator=" Please Wait...."  loading={(e)=>submitAdharVarifyByOtpButton(e)}
                                >
                                  Send OTP
                                </button>
                              </div>
                            </div>

                            {showOtpResults ?
                              <div className="row d-flex align-items-center">

                                <div className="col-md-7">
                                  <div className="mb-3 mt-3 ">
                                    <input
                                      onChange={(e) => {
                                        setsubmitAadharOtp(e.target.value);
                                        setsubmitAadharOtpErr("")
                                      }}
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter OTP"
                                    // value={otpReceive}
                                    />
                                    {submitAadharOtpErr ? <p style={{ color: "red" }}>{submitAadharOtpErr}</p> : ''}
                                  </div>

                                </div>

                                <div className="col-md-4">

                                  <button
                                    type="submit"
                                    onClick={(e) => VarifyAdharOtp(e)}
                                    className="default-button"
                                  >
                                    Submit OTP & Next
                                  </button>

                                </div>

                              </div>
                              : ""}


                          </form>


                          // </>

                          : ''}



                      </TabPanel>




                      {/* pan Verify */}

                      {
                        dataClient.pan === 1 ? <>
                          <TabPanel value={values} index={
                            (() => {
                              // if (dataClient.adhaar === 0) return 0
                              if (dataClient.adhaar === 0 && dataClient.adhaar_verify_with_otp === 0) return 0
                              // if (dataClient.adhaar === 1 && dataClient.adhaar_verify_with_otp === 0) return 0
                              if (dataClient.adhaar === 0 || dataClient.adhaar_verify_with_otp === 1 && dataClient.otpbased === 0) return 1;
                              return 1
                            })()
                            // 1
                          }>
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputPassword1"
                                placeholder="Pan No."
                                onChange={(e) => setPanNo(e.target.value.toUpperCase())}
                                value={panNo}
                              />
                              {PanErr ? <p style={{ color: "red" }}>{PanErr}</p> : ""}

                            </div>

                            <button
                              // type="submit"
                              onClick={(e) => submitPanVarify(e)}
                              className="default-button"
                            >
                              Verify
                            </button>

                          </TabPanel>

                        </> : ""}

                      {dataClient.otpbased === 1 ? <>
                        <TabPanel value={values} index={
                          // (dataClient.adhaar === 0 ? 1 : 2)
                          (() => {
                            if (dataClient.adhaar === 0 && dataClient.adhaar_verify_with_otp === 0) return 1

                            // if (dataClient.adhaar === 1 && dataClient.adhaar_verify_with_otp === 0) return 1
                            // if (dataClient.adhaar === 0) return 1;
                            return 2
                          })()
                        }
                        // className={clientOtp.length < 6 ? "d-none" : " d-block"}
                        >
                          <form>
                            <div className="row d-flex align-items-center">
                              <div className="col-md-8">
                                <input
                                  onChange={(e) => {
                                    setMobileNo(e.target.value);
                                    setMobileNoErr("");
                                    if (!e.target.value.length > 10) {
                                      setMobileNoErr("error");
                                    }
                                  }}
                                  value={mobileNo}
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter Contact No."
                                  max="5"

                                />
                                {mobileNoErr ? <p>{mobileNoErr}</p> : ""}
                              </div>
                              <div className="col-md-4 float-lg-right">
                                <button
                                  type="submit"
                                  onClick={(e) => submitOtpVarify(e)}
                                  className="default-button sendOTP"
                                  disabled={buttondDissabled}
                                >
                                  Send OTP
                                </button>
                              </div>

                              <div className="col-md-6 pt-3">
                                <button
                                  type="submit"
                                  onClick={(e) => ResendOtp(e)}
                                  className="link-btn"
                                  disabled={!buttondDissabled}
                                >
                                  Resend OTP
                                </button>
                              </div>
                            </div>

                            {showResults ? <>
                              <div className="row d-flex align-items-center">
                                <div className="col-md-8">
                                  <div className="mb-3 mt-3 ">
                                    <input
                                      onChange={(e) => {
                                        setOtpReceive(e.target.value);
                                        setOtpReceiveErr("")
                                      }}
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter OTP"
                                      value={otpReceive}
                                    />
                                    <p style={{ color: "red" }}>{otpReceiveErr}</p>
                                  </div>

                                </div>

                                <div className="col-md-4">

                                  <button
                                    type="submit"
                                    onClick={(e) => VarifyOtp(e)}
                                    className="default-button"
                                  >
                                    Submit OTP & Next
                                  </button>

                                </div>

                              </div>
                            </> : " "}


                          </form>
                        </TabPanel>
                      </> : ""}


                      {/* esign */}


                      {
                        dataClient.adhaar_sign === 1 ? <>
                          <TabPanel value={values} index={
                            (() => {
                              if (dataClient.adhaar === 0 && dataClient.otpbased === 1) if (dataClient.adhaar_verify_with_otp === 0) return 2
                              if (dataClient.pan === 0) return 1

                              if (dataClient.otpbased === 0) if (dataClient.adhaar === 0 && dataClient.adhaar_verify_with_otp === 0) return 1
                              if (dataClient.otpbased === 0) return 2
                              return 3
                            })()
                            // 2
                          }
                          >

                            <Box sx={{ width: '100%' }}>
                              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                {/*  dcocument  */}
                                <Tabs value={value} onChange={handleEsignChange} aria-label="basic tabs example" centered>
                                  <Tab label="Step 1" {...a11yProps(0)} disabled />
                                  <Tab label="step 2" {...a11yProps(1)} disabled />
                                </Tabs>
                              </Box>
                              <TabPanel value={value} index={0} >
                                <form>

                                  <div className="mb-3">


                                    <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
                                    <label htmlFor='name'>Full Name</label>
                                    <input type="text" className="form-control" name="fullname" value={dataClient.fullname} readOnly='true' placeholder='Full Name' id="name" />

                                  </div>
                                  <div className="mb-3">
                                    <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
                                    <label htmlFor='mobile'>Mobile No.</label>
                                    <input type="number" className="form-control" name="mobileno" value={dataClient.personal_contact} readOnly='true' placeholder='Mobile No.' id="mobile" />
                                  </div>
                                  <div className="mb-3">
                                    <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
                                    <label htmlFor='email'>Email</label>
                                    <input type="email" className="form-control" name="email" value={dataClient.email} readOnly='true' placeholder='Email' id='email' />
                                  </div>
                                  <button
                                    // type="submit"
                                    onClick={(e) => submitVarifydoc(e)}
                                    className="default-button"
                                  >
                                    Verify & Next
                                  </button>
                                </form>

                              </TabPanel>
                              <TabPanel value={value} index={1}  >

                                <div className='mb-3'>
                                  <form >

                                    <div className="form-group file-area">
                                      <div className="d-flex justify-content-center">
                                        <iframe
                                          className="d-flex"
                                          src={`/images/e_sign-${(dataClient.document)}`}
                                          title="Your Document"
                                          width="350"
                                          height="280"
                                        />
                                      </div>

                                    </div>
                                  </form>

                                  <div className="form-group">
                                    <button type="submit " className="default-button" onClick={(e) => submitVarifyDocument(e)}>Confirm & Next </button>
                                  </div>

                                </div>
                              </TabPanel>

                            </Box>
                          </TabPanel>
                        </> : ""}


                      {alertMsg && (
                        <AlertMessages
                          hideAlert={hideClose}
                          showAlert={alertMsg}
                          message={textAlert}
                          alertColor={alertColor}
                        />
                      )}
                    </Box>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div >

    </>
  );
}




