
// /* eslint no-var: 0 */
// import React, { useEffect, useState } from 'react'
// import { Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
// import { Card, Stack, Button, Container, Typography, TextField } from '@mui/material';
// import { Col, Form, Row, Modal } from 'react-bootstrap';
// import { Icon } from '@iconify/react';
// // Common DATA Table
// import DataTable from '../../../utils/DataTable'
// import Iconify from '../../../components/Iconify';
// import Page from '../../../components/Page';
// // API Function
// import { AdminTrasnectionHistory } from '../../../services';
// // Date Format
// import { fDate } from '../../../utils/formatTime';
// import AlertMessages from '../../../utils/AlertMessages';

// const TransecectionHistory = () => {


//     const [fundData, setFundData] = useState([]);
//     const SuperAdminToken = JSON.parse(localStorage.getItem('admin')).accessToken;
//     const adminId = JSON.parse(localStorage.getItem('admin')).id;


//     const columns = [
//         {
//             name: 'User Details',
//             selector: row => row.userdetails,
//             sortable: true,
//             width: '230px !important',
//         },
//         {
//             name: 'Sign Type',
//             selector: row => row.sign_type,
//             sortable: true,
//             width: '230px !important',
//         },
//         {
//             name: 'Sign Charge',
//             selector: row => row.sign_charge,
//             sortable: true,
//             width: '230px !important',
//         },
//         {
//             name: 'Available Fund',
//             selector: row => row.fund,
//             sortable: true,
//             width: '170px !important',
//         },
//         {
//             name: 'Remaining Fund',
//             selector: row => row.fundremain,
//             sortable: true,
//             width: '170px !important',
//         },

//         {
//             name: 'Action',
//             selector: row => row.action,
//             sortable: true,
//             width: '150px !important',
//         },
//     ];

//     const dataFund = [];
//     const getFundHistory = async () => {

//       const  data = {
//         "adminid": adminId 
//       }
//         const response = await AdminTrasnectionHistory( data, SuperAdminToken)
//         if (response) {
//             response.forEach((fund) => {
//                 dataFund.push({

//                     action: fund.action,

//                     fund: fund.fund,
//                     fundremain: fund.fundremain,
//                     sign_charge: fund.sign_charge,
//                     sign_type: fund.sign_type,
//                     userdetails: fund.userdetails.username
//                 })
//                 setFundData(dataFund)
//             })

//             // console.log("fundhistory", fundData);
//         }

//     }

//     useEffect(() => {
//         getFundHistory();
//     }, []);


//     return (
//         <>
//             <Page title="User">
//                 <Container>
//                     <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
//                         <Typography variant="h4" gutterBottom>
//                         Transaction History
//                         </Typography>
//                     </Stack>
//                     <Card>
//                         <DataTable columns={columns} data={fundData} />
//                     </Card>
//                 </Container>


//             </Page>
//         </>
//     )
// }

// export default TransecectionHistory












import React, { useEffect, useState } from 'react'
import { Link as RouterLink, NavLink, useParams, useLocation } from 'react-router-dom';
import { Card, Table, Stack, Avatar, Button, Checkbox, TableRow, Alert, TableBody, TableCell, Container, Typography, Tooltip, TableContainer, TablePagination, } from '@mui/material';
import { Icon } from '@iconify/react';
// dialog Box

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import DataTable from "../../../utils/DataTable";
import { fDateTime } from '../../../utils/formatTime';
import Iconify from '../../../components/Iconify';
import { AdminTrasnectionHistory } from '../../../services';
import AlertMessages from '../../../utils/AlertMessages';

const TransecectionHistory = () => {
    const location = useLocation()

    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [dialogData, setDialogData] = useState([]);
    const [refresh, setrefresh] = useState(true)
    const [alertMsg, setAlertMsg] = useState(false)
    const [alertColor, setAlertColor] = useState("")
    const [textAlert, setTextAlert] = useState("")

    const adminToken = JSON.parse(localStorage.getItem('admin')).accessToken;
    const adminId = JSON.parse(localStorage.getItem('admin')).id;


    // dialogbox

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = async (data) => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    // dialogbox
    const hideClose = () => {
        setAlertMsg(false)
    }


    const getTransactionHistory = async () => {
        const data = {
            "adminid": adminId
        }
        const response = await AdminTrasnectionHistory(data, adminToken)
        if (response) {
            setData(response);
        }
    }



    useEffect(() => {
        getTransactionHistory();

    }, [refresh]);




    const columns = [
        // {
        //     label: 'Sr .No',
        //     name: "ids",
        //     width: '150px !important',
        //     options: {
        //         customBodyRender: (value, tableMeta, rowData, updateValue) => {
        //             // return tableMeta.rowIndex + 1

        //         },

        //     }
        // },
        {
            label: 'Created At',
            name: `createdAt`,
            width: '150px !important',
            options: {
                customBodyRender: (value, tableMeta, rowData, updateValue) => {
                    return (
                        <p>
                            {
                                fDateTime(tableMeta.rowData[1])
                                // console.log("dddd" ,tableMeta.rowData)
                            }
                        </p>
                    )
                }
            },
        },
        {
            label: 'Client Name',
            name: "userdetails.fullname",
            width: '150px !important',
            textAlign: "center"
        },


        {
            label: 'Verification Type',
            name: "sign_type",
            width: '170px !important',
            textAlign: "center",
        },
        {
            label: 'Charges',
            name: "sign_charge",
            width: '150px !important',
            textAlign: "center"
        },
        {
            label: 'Available Balance',
            name: "fundremain",
            width: '170px !important',
            textAlign: "center",
        },

    ];



    return (

        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Company Transaction History
                    </Typography>
                  
                </Stack>

                <DataTable
                    columns={columns}
                    data={data}

                />

                {/* dialog Box  */}
                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth='xl'
                >

                    <DialogContent>
                        <DialogContentText>
                            <h4 style={{ fontWeight: "bolder", fontSize: "36px" }}>
                                Package Details
                            </h4>
                            <div className="w-100 row mb-4 mt-4">
                                <div className="col-md-4">
                                    <Typography variant="h6" gutterBottom>
                                        Package Name
                                    </Typography>
                                    <p>{dialogData[2]} </p>
                                </div>
                                <div className="col-md-4">
                                    <Typography variant="h6" gutterBottom>
                                        Deposit Limit
                                    </Typography>
                                    <p>{dialogData[3]} </p>
                                </div>
                                {/* <div className="col-md-4">
                    <Typography variant="h6" gutterBottom>
                   Package Days/Month/year
                    </Typography>
                    <p>hello</p>
                  </div> */}
                                <div className="col-md-3">
                                    <Typography variant="h6" gutterBottom>
                                        Package Validity
                                    </Typography>
                                    <p>{dialogData[4]} {dialogData[5]} </p>
                                </div>
                            </div>
                            <h4 style={{ fontWeight: "bolder", fontSize: "36px" }}>
                                Price
                            </h4>
                            <div className="row mb-3 mt-4">
                                <div className="col-4">
                                    <Typography variant="h6" gutterBottom>
                                        Aadhar Verification
                                    </Typography>
                                    <p>{dialogData[6]} </p>
                                </div>
                                <div className="col-4">
                                    <Typography variant="h6" gutterBottom>
                                        Pan verification
                                    </Typography>
                                    <p>{dialogData[7]} </p>
                                </div>
                                <div className="col-4">
                                    <Typography variant="h6" gutterBottom>
                                        E-sign
                                    </Typography>
                                    <p>{dialogData[8]} </p>
                                </div>
                                <div className="col-md-3">
                                    <Typography variant="h6" gutterBottom>
                                        Package Details
                                    </Typography>
                                    <p>{dialogData[9]}</p>
                                </div>
                            </div>

                        </DialogContentText>
                    </DialogContent>
                    {/* <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Disagree
            </Button>
            <Button onClick={handleClose} autoFocus>
              Agree
            </Button>
          </DialogActions> */}
                </Dialog>

                {/* dialog box */}



                {alertMsg && (
                    <AlertMessages
                        hideAlert={hideClose}
                        showAlert={alertMsg}
                        message={textAlert}
                        alertColor={alertColor}
                    />
                )}

            </Container>

        </>
    )
}

export default TransecectionHistory

