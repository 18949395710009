import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Button  } from '@mui/material';
// components
import Page from '../../components/Page';

import Iconify from '../../components/Iconify';
import { AdminDashboardCount } from '../../services';
import { AppWidgetSummary } from '../../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();

  const [showCount, setShowCount] = useState("");


  const countDashboard = () => {

    const adminID = JSON.parse(localStorage.getItem('admin')).id;
    // const adminRole = JSON.parse(localStorage.getItem('admin')).roles[0];
    // const superadminID = JSON.parse(localStorage.getItem('superadmin')).id;
    const SuperAdminToken = JSON.parse(localStorage.getItem('admin')).accessToken;
    // console.log("sadminDashboard", adminRole);

    const data = {
      "adminid": adminID,
    }
    
    const response = AdminDashboardCount(data, SuperAdminToken)
    response.then((val) => {
      if (val) {
        setShowCount(val)
      }
    })
  }

  useEffect(() => {
    countDashboard();
  }, [])




  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
         <div className='d-flex mb-5'>
        <Typography variant="h4" className='flex-grow-1'  >
            Admin Dashboard
          </Typography>
          <Button className="mx-1" style={{ color: '#fff' }} variant="contained" component={RouterLink} to="/admin/addclient" startIcon={<Iconify icon="line-md:plus" />}>
            Add Client
          </Button>
    
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <NavLink
              to={"/admin/clientlist"}
              style={{ textDecoration: 'none' }}
            >
              <AppWidgetSummary title="All Users" total={showCount.alluser} icon={'fa-solid:users'} />
            </NavLink>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <NavLink
              to={"/admin/clientlist"}
              style={{ textDecoration: 'none' }}
            >
              <AppWidgetSummary title="Active Users" total={showCount.activeuser} color="info" icon={'fa6-solid:user-large'} />
            </NavLink>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <NavLink
              to={"/admin/clientlist"}
              style={{ textDecoration: 'none' }}
            >
              <AppWidgetSummary title="Inactive Users" total={showCount.inactiveuser} color="warning" icon={'fa6-solid:user-large-slash'} />
            </NavLink>
          </Grid>
          {/* 
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bug Reports" total={234} color="error" icon={'ant-design:bug-filled'} />
          </Grid> */}

        </Grid>
      </Container>
    </Page>
  );
}
