// /* eslint no-var: 0 */
// import React, { useEffect, useState } from 'react'
// import { Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
// import { Card, Stack, Button, Container, Typography, TextField } from '@mui/material';
// import { Col, Form, Row, Modal } from 'react-bootstrap';
// import * as Yup from 'yup';
// import { useFormik, FormikProvider } from 'formik';
// import { Icon } from '@iconify/react';
// import Tooltip from '@mui/material/Tooltip';
// import * as Common from '../../utils/CommonMessage';
// // Common DATA Table
// import DataTable from '../../utils/DataTable'
// import Iconify from '../../components/Iconify';
// import Page from '../../components/Page';
// // API Function
// import { getAdmins, deleteAdminById, adminUpdate, getAdminById, getStatus } from '../../services';
// // Date Format
// import { fDate } from '../../utils/formatTime';
// import AlertMessages from '../../utils/AlertMessages';

// const AdminList = () => {

//   const [data, setData] = useState([]);
//   const [refresh, setrefresh] = useState(true)
//   const [alertMsg, setAlertMsg] = useState(false)
//   const [alertColor, setAlertColor] = useState("")
//   const [textAlert, setTextAlert] = useState("")

//   const [fund, setFund] = useState("")
//   const [rowId, setRowId] = useState("")
//   const [abc, setAbc] = useState("")


//   const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);
//   // const handleShow = () => setShow(true);

//   const { id } = useParams()
//   const location = useLocation();
//   const navigate = useNavigate();
//   const data1 = [];
//   const SuperAdminToken = JSON.parse(localStorage.getItem('superadmin')).accessToken;

//   // console.log("token", SuperAdminToken);


//   const columns = [
//     {
//       name: 'FullName',
//       selector: row => row.fullname,
//       sortable: true,
//       width: '150px !important',
//     },
//     // {
//     //   name: 'UserName',
//     //   selector: row => row.username,
//     //   sortable: true,
//     //   width: '150px !important',
//     // },
//     {
//       name: 'Email',
//       selector: row => row.email,
//       sortable: true,
//       width: '230px !important',
//     },
//     {
//       name: 'Company Email',
//       selector: row => row.company_email,
//       sortable: true,
//       width: '230px !important',
//     },
//     // {
//     //   name: 'fullname',
//     //   label: 'Fullname',
//     //   sortable: true,
//     //   // width: '150px !important',
//     // },
//     // {
//     //   name: 'username',
//     //   label: 'Username',
//     //   sortable: true,
//     //   // width: '150px !important',
//     // },
//     // {
//     //   name: 'email',
//     //   label: 'Email',
//     //   sortable: true,
//     //   // width: '230px !important',
//     // },
//     // {
//     //   name: 'company_email',
//     //   label: 'Company Email',
//     //   sortable: true,
//     //   // width: '230px !important',
//     // },
//     // {
//     //   name: 'Mobile',
//     //   selector: row => row.personal_contact,
//     //   sortable: true,
//     //   width: '150px !important',
//     // },
//     // {
//     //   name: 'Company Contact',
//     //   selector: row => row.company_contact,
//     //   sortable: true,
//     //   width: '150px !important',
//     // },
//     // {
//     //   name: 'Domain',
//     //   selector: row => row.company_domain,
//     //   sortable: true,
//     //   width: '150px !important',
//     // },
//     // {
//     //   name: 'Fund',
//     //   selector: row => row.fund,
//     //   sortable: true,
//     //   width: '150px !important',
//     // },
//     // {
//     //   name: 'Pan Verify',
//     //   selector: row => row.pan,
//     //   sortable: true,
//     //   width: '150px !important',
//     // },
//     // {
//     //   name: 'Adhar  Verify',
//     //   selector: row => row.adhaar,
//     //   sortable: true,
//     //   width: '150px !important',
//     // },
//     // {
//     //   name: 'Adhar Sign',
//     //   selector: row => row.adhaar_sign,
//     //   sortable: true,
//     //   width: '150px !important',
//     // },
//     // {
//     //   name: 'Address',
//     //   selector: row => row.address,
//     //   sortable: true,
//     //   width: '150px !important',
//     // },
//     // {
//     //   name: 'E Stamp',
//     //   selector: row => row.e_stamp,
//     //   sortable: true,
//     //   width: '150px !important',
//     // },
//     // {
//     //   name: 'Create Date',
//     //   selector: row => row.createdAt,
//     //   sortable: true,
//     //   width: '150px !important',
//     // },

//     // {
//     //   label: 'Status',
//     //   name:'status'
//     //   // options:{

//     //   //     <Form.Check
//     //   //       type="switch"
//     //   //       id="custom-switch"
//     //   //       onClick={(m) => adminStatus(m, row.id)}
//     //   //       // defaultChecked={() => defaultChecked(row.status)}
//     //   //       defaultChecked={(row.status === 1 ? 'true' : 'false')}
//     //   //     />

//     //   // }
//     // },

//     // {
//     //   label :"Status",
//     //   name: "status",
//     //   options: {
//     //     customBodyRender: (value, tableMeta, updateValue) => {
//     //       return (
//     //         <Form.Check
//     //           type="switch"
//     //           id="custom-switch"
//     //           // onClick={(m) => adminStatus(m, row.id)}
//     //           // defaultChecked={() => defaultChecked(row.status)}
//     //           // defaultChecked={(row.status === 1 ? 'true' : 'false')}
//     //         />
//     //       );
//     //     }
//     //   }
//     // },

//     {
//       name: 'Status',
//       selector: (row) => (
//         <>
//           <Form.Check
//             type="switch"
//             id="custom-switch"
//             onClick={(m) => adminStatus(m, row.id)}
//             // defaultChecked={() => defaultChecked(row.status)}
//             defaultChecked={(row.status === 1 ? 'true' : 'false')}
//           />
//         </>
//       )
//     },



//     {
//       name: "ACTIONS",
//       width: '250px !important',
//       selector: (row) => (
//         <>

//           <NavLink
//             to={`/superadmin/transactionhistory`}
//           >
//             <Tooltip title="Transaction History">
//               <Icon icon="icon-park-outline:transaction-order"
//                 color="#A8006D"
//                 className='mx-2'
//                 width="22"
//                 variant="primary"
//                 data-toggle="tooltip"
//                 data-placement="top"
//                 title="Transaction History"
//               />
//             </Tooltip>
//           </NavLink>

//           <NavLink
//             to={`/superadmin/viewdetails/${row.id}`}
//             state={row}
//           >
//             <Tooltip title="View Details">
//               <Icon icon="clarity:grid-view-line"
//                 color="#E43F47"
//                 className='mx-2'
//                 width="22"
//                 variant="primary"
//                 data-toggle="tooltip"
//                 data-placement="top"
//                 title="Edit Client"
//               />
//             </Tooltip>
//           </NavLink>

//           <Tooltip title="Add Funds">
//             <Icon icon="bx:money-withdraw"
//               color="#FF822F"
//               className='mx-2'
//               width="22"
//               variant="primary"
//               data-toggle="tooltip"
//               data-placement="top"
//               title="Edit Funds"
//               cursor="pointer"
//               // onClick={handleShow}
//               onClick={() => handleShow(row.id)}
//             />
//           </Tooltip>


//           {/* {console.log("data",row)} */}
//           <NavLink
//             to={`/superadmin/editadmin/${row.id}`}
//             state={row}
//           >
//             <Tooltip title="Edit">
//               <Icon icon="akar-icons:edit"
//                 color="#6BAA2C"
//                 className='mx-2'
//                 width="22"
//                 variant="primary"
//                 data-toggle="tooltip"
//                 data-placement="top"
//                 title="Edit Client"
//               />
//             </Tooltip>
//           </NavLink>

//           <Tooltip title="Delete">
//             <Icon icon="ant-design:delete-outlined"
//               color="CD2B2E"
//               width="22"
//               data-toggle="tooltip"
//               data-placement="top"
//               title="Delete"
//               cursor="pointer"
//               onClick={() => deleteItem(row.id)}
//             />
//           </Tooltip>
//         </>
//       ),
//     },
//   ];


//   // Get API For Get AllAdmins Data

//   const getAllAdmins = async () => {
//     const response = await getAdmins(SuperAdminToken)
//     if (response) {

//       response.forEach((item) => {
//         data1.push({
//           id: item.id,
//           fullname: item.fullname,
//           username: item.username,
//           email: item.email,
//           company_email: item.company_email,
//           personal_contact: item.personal_contact,
//           company_contact: item.company_contact,
//           company_domain: item.company_domain,
//           fund: item.fund,
//           status: item.status,
//           pan: item.pan,
//           adhaar: item.adhaar,
//           adhaar_sign: item.adhaar_sign,
//           e_stamp: item.e_stamp,
//           address: item.address,
//           package_id: item.package_id,
//           // expiry_date: fDate(item.expiry_date),
//           createdAt: fDate(item.createdAt)
//         })
//       })
//       setData(data1);
//     }
//   }
//   // const defaultChecked = (a) => {
//   //   console.log("status", a);
//   // }

//   // console.log("respo", data.id)
//   // console.log('data1', data);


//   // Delete Admin API

//   const deleteItem = async (id) => {
//     if (window.confirm("Do you want to delete this Client ?")) {
//       const response = await deleteAdminById(SuperAdminToken, id)
//       setrefresh(!refresh)

//       if (response.message) {
//         setAlertMsg(true)
//         setAlertColor("success")
//         setTextAlert(response.message)
//       }
//     }
//   }

//   // Admin Status API

//   const adminStatus = async (m, rowid) => {

//     // console.log("id", id.target.checked);
//     // console.log("id", m.target.checked);

//     if (m.target.checked) {
//       const data = {
//         "admin_id": rowid,
//         "status": 1
//       }
//       const response = await getStatus(SuperAdminToken, data)
//     }
//   }


//   const handleShow = async (id) => {
//     const response = await getAdminById(SuperAdminToken, id);
//     if (response) {
//       setShow(true);
//       setFund(response.fund)
//       setRowId(response.id)
//     }

//     // console.log("response" ,response);
//     // setShow(true)
//     // alert(id)
//   }

//   useEffect(() => {
//     getAllAdmins();
//   }, [refresh]);


//   // console.log("respo", alertMsg)
//   const hideClose = () => {
//     setAlertMsg(false)
//   }

//   const registerForm = Yup.object().shape({
//     fund: Yup.string().required(Common.FUNDS),
//   });

//   const formik = useFormik({
//     initialValues: {
//       // fund: location.state.fund,
//     },
//     validationSchema: registerForm,

//     // onSubmit: async () => {
//     //   const data1 = {
//     //     "fund": values.fund,
//     //   }
//     //   // console.log("date", values.fund);
//     //   const response = await adminUpdate(data1, id, SuperAdminToken);
//     //   console.log("respx", response);
//     //   if (response.message) {
//     //     setAlertMsg(true)
//     //     setAlertColor("success")
//     //     setTextAlert(response.message)
//     //     setTimeout(() => navigate('/superadmin/adminlist'), 1000)
//     //   }
//     // },
//   })


//   const updateFund = async (rowId) => {
//     // console.log("SuperAdminToken", fund)
//     // console.log("id", id);
//     const data1 = {
//       "fund": abc,
//     }
//     // console.log("date", values.fund);

//     const response = await adminUpdate(data1, rowId, SuperAdminToken);
//     // console.log("respx", response);
//     if (response.message) {
//       setAlertMsg(true)
//       setAlertColor("success")
//       setTextAlert(response.message)
//       setTimeout(() => navigate('/superadmin/adminlist'), 1000)
//     }
//   }

//   const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

//   // console.log("fund", values);

//   return (
//     <>
//       {/* <Alert severity="success" className={alertMsg ? "d-block": "d-none"}>{alertMsg}</Alert> */}
//       <Page title="User">
//         <Container>
//           <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
//             <Typography variant="h4" gutterBottom>
//              Company List
//             </Typography>
//             <Button style={{ color: '#fff' }} variant="contained" component={RouterLink} to="/superadmin/addadmin" startIcon={<Iconify icon="line-md:plus" />}>
//               Add Company
//             </Button>
//           </Stack>
//           <Card>
//             <DataTable columns={columns} data={data} />
//           </Card>
//         </Container>


//         <FormikProvider value={formik}>
//           <Form noValidate onSubmit={handleSubmit}>
//             <Modal show={show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
//               <Modal.Header closeButton>
//                 <Modal.Title id="contained-modal-title-vcenter">Add Funds</Modal.Title>
//               </Modal.Header>
//               <Modal.Body>

//                 <Row className="mb-3 ms-3">
//                   <Form.Group as={Col} controlId="formGridCompanyContact">
//                     <TextField style={{ width: 400 }} label="Funds" type='number' color="secondary"
//                       // {...getFieldProps('fund')}
//                       onChange={(e) => setAbc(e.target.value)}
//                       value={fund}
//                       error={Boolean(touched.fund && errors.fund)}
//                       helperText={touched.fund && errors.fund} />
//                   </Form.Group>

//                   <Form.Group as={Col} controlId="formGridCompanyContact">
//                     <TextField style={{ width: 400 }} label="Funds" type='number' color="secondary" />
//                   </Form.Group>
//                 </Row>

//               </Modal.Body>
//               <Modal.Footer>

//                 <Button variant="primary" onClick={() => updateFund(rowId)} >
//                   Update
//                 </Button>
//               </Modal.Footer>
//             </Modal>

//           </Form>
//         </FormikProvider>
//       </Page>


//       {alertMsg &&
//         <AlertMessages
//           hideAlert={hideClose}
//           showAlert={alertMsg}
//           message={textAlert}
//           alertColor={alertColor}
//         />
//       }
//     </>

//   )
// }

// export default AdminList



// import React, { useEffect, useState } from 'react'
// import { Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
// import { Card, Stack, Button, Container, Typography, TextField } from '@mui/material';
// import { Col, Form, Row, Modal } from 'react-bootstrap';
// import * as Yup from 'yup';
// import { useFormik, FormikProvider } from 'formik';
// import { Icon } from '@iconify/react';
// import Tooltip from '@mui/material/Tooltip';
// import * as Common from '../../utils/CommonMessage';
// // Common DATA Table
// import DataTable from '../../utils/DataTable'
// import Iconify from '../../components/Iconify';
// import Page from '../../components/Page';
// // API Function
// import { getAdmins, deleteAdminById, adminUpdate, getAdminById, getStatus } from '../../services';
// // Date Format
// import { fDate } from '../../utils/formatTime';
// import AlertMessages from '../../utils/AlertMessages';

// const AdminList = () => {

//   const [data, setData] = useState([]);
//   const [refresh, setrefresh] = useState(true)
//   const [alertMsg, setAlertMsg] = useState(false)
//   const [alertColor, setAlertColor] = useState("")
//   const [textAlert, setTextAlert] = useState("")

//   const [fund, setFund] = useState("")
//   const [rowId, setRowId] = useState("")
//   const [abc, setAbc] = useState("")


//   const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);
//   // const handleShow = () => setShow(true);

//   const { id } = useParams()
//   const location = useLocation();
//   const navigate = useNavigate();
//   const data1 = [];
//   const SuperAdminToken = JSON.parse(localStorage.getItem('superadmin')).accessToken;

//   console.log("token", SuperAdminToken);


//   const columns = [
//     {
//       name: 'FullName',
//       selector: row => row.fullname,
//       sortable: true,
//       width: '150px !important',
//     },
//     {
//       name: 'UserName',
//       selector: row => row.username,
//       sortable: true,
//       width: '150px !important',
//     },
//     {
//       name: 'Email',
//       selector: row => row.email,
//       sortable: true,
//       width: '230px !important',
//     },
//     {
//       name: 'Company Email',
//       selector: row => row.company_email,
//       sortable: true,
//       width: '230px !important',
//     },
//     // {
//     //   name: 'Mobile',
//     //   selector: row => row.personal_contact,
//     //   sortable: true,
//     //   width: '150px !important',
//     // },
//     // {
//     //   name: 'Company Contact',
//     //   selector: row => row.company_contact,
//     //   sortable: true,
//     //   width: '150px !important',
//     // },
//     // {
//     //   name: 'Domain',
//     //   selector: row => row.company_domain,
//     //   sortable: true,
//     //   width: '150px !important',
//     // },
//     // {
//     //   name: 'Fund',
//     //   selector: row => row.fund,
//     //   sortable: true,
//     //   width: '150px !important',
//     // },
//     // {
//     //   name: 'Pan Verify',
//     //   selector: row => row.pan,
//     //   sortable: true,
//     //   width: '150px !important',
//     // },
//     // {
//     //   name: 'Adhar  Verify',
//     //   selector: row => row.adhaar,
//     //   sortable: true,
//     //   width: '150px !important',
//     // },
//     // {
//     //   name: 'Adhar Sign',
//     //   selector: row => row.adhaar_sign,
//     //   sortable: true,
//     //   width: '150px !important',
//     // },
//     // {
//     //   name: 'Address',
//     //   selector: row => row.address,
//     //   sortable: true,
//     //   width: '150px !important',
//     // },
//     // {
//     //   name: 'E Stamp',
//     //   selector: row => row.e_stamp,
//     //   sortable: true,
//     //   width: '150px !important',
//     // },
//     // {
//     //   name: 'Create Date',
//     //   selector: row => row.createdAt,
//     //   sortable: true,
//     //   width: '150px !important',
//     // },

//     {
//       name: 'Status',
//       selector: (row) => (
//         <>
//           <Form.Check
//             type="switch"
//             id="custom-switch"
//             onClick={(m) => adminStatus(m, row.id)}
//             // defaultChecked={() => defaultChecked(row.status)}
//             defaultChecked={(row.status === 1 ? 'true' : 'false')}
//           />
//         </>
//       )
//     },
//     {
//       name: "ACTIONS",
//       width: '250px !important',
//       selector: (row) => (
//         <>

//           <NavLink
//             to={`/superadmin/transactionhistory`}
//           >
//             <Tooltip title="Transaction History">
//               <Icon icon="icon-park-outline:transaction-order"
//                 color="#A8006D"
//                 className='mx-2'
//                 width="22"
//                 variant="primary"
//                 data-toggle="tooltip"
//                 data-placement="top"
//                 title="Transaction History"
//               />
//             </Tooltip>
//           </NavLink>

//           <NavLink
//             to={`/superadmin/viewdetails/${row.id}`}
//             state={row}
//           >
//             <Tooltip title="View Details">
//               <Icon icon="clarity:grid-view-line"
//                 color="#E43F47"
//                 className='mx-2'
//                 width="22"
//                 variant="primary"
//                 data-toggle="tooltip"
//                 data-placement="top"
//                 title="Edit Client"
//               />
//             </Tooltip>
//           </NavLink>

//           <Tooltip title="Edit Funds">
//             <Icon icon="bx:money-withdraw"
//               color="#FF822F"
//               className='mx-2'
//               width="22"
//               variant="primary"
//               data-toggle="tooltip"
//               data-placement="top"
//               title="Edit Funds"
//               cursor="pointer"
//               // onClick={handleShow}
//               onClick={() => handleShow(row.id)}
//             />
//           </Tooltip>


//           <NavLink
//             to={`/superadmin/editadmin/${row.id}`}
//             state={row}
//           >
//             <Tooltip title="Edit">
//               <Icon icon="akar-icons:edit"
//                 color="#6BAA2C"
//                 className='mx-2'
//                 width="22"
//                 variant="primary"
//                 data-toggle="tooltip"
//                 data-placement="top"
//                 title="Edit Client"
//               />
//             </Tooltip>
//           </NavLink>

//           <Tooltip title="Delete">
//             <Icon icon="ant-design:delete-outlined"
//               color="CD2B2E"
//               width="22"
//               data-toggle="tooltip"
//               data-placement="top"
//               title="Delete"
//               cursor="pointer"
//               onClick={() => deleteItem(row.id)}
//             />
//           </Tooltip>
//         </>
//       ),
//     },
//   ];


//   // Get API For Get AllAdmins Data

//   const getAllAdmins = async () => {
//     const response = await getAdmins(SuperAdminToken)
//     if (response) {

//       response.forEach((item) => {
//         data1.push({
//           id: item.id,
//           fullname: item.fullname,
//           username: item.username,
//           email: item.email,
//           company_email: item.company_email,
//           personal_contact: item.personal_contact,
//           company_contact: item.company_contact,
//           company_domain: item.company_domain,
//           fund: item.fund,
//           status: item.status,
//           pan: item.pan,
//           adhaar: item.adhaar,
//           adhaar_sign: item.adhaar_sign,
//           e_stamp: item.e_stamp,
//           address: item.address,
//           package_id: item.package_id,
//           // expiry_date: fDate(item.expiry_date),
//           createdAt: fDate(item.createdAt)
//         })
//       })
//       setData(data1);
//     }
//   }
//   // const defaultChecked = (a) => {
//   //   console.log("status", a);
//   // }

//   // console.log("respo", data.id)
//   // console.log('data1', data);


//   // Delete Admin API

//   const deleteItem = async (id) => {
//     if (window.confirm("Do you want to delete this Client ?")) {
//       const response = await deleteAdminById(SuperAdminToken, id)
//       setrefresh(!refresh)

//       if (response.message) {
//         setAlertMsg(true)
//         setAlertColor("success")
//         setTextAlert(response.message)
//       }
//     }
//   }

//   // Admin Status API

//   const adminStatus = async (m, rowid) => {


//     if (m.target.checked) {
//       const data = {
//         "admin_id": rowid,
//         "status": 1
//       }
//       const response = await getStatus(SuperAdminToken, data)
//     }
//   }


//   const handleShow = async (id) => {
//     const response = await getAdminById(SuperAdminToken, id);
//     if (response) {
//       setShow(true);
//       setFund(response.fund)
//       setRowId(response.id)
//     }

//     // console.log("response" ,response);
//     // setShow(true)
//     // alert(id)

//   }

//   useEffect(() => {
//     getAllAdmins();
//   }, [refresh]);


//   // console.log("respo", alertMsg)
//   const hideClose = () => {
//     setAlertMsg(false)
//   }

//   const registerForm = Yup.object().shape({
//     fund: Yup.string().required(Common.FUNDS),
//   });

//   const formik = useFormik({
//     initialValues: {
//       // fund: location.state.fund,
//     },
//     validationSchema: registerForm,

//     onSubmit: async () => {
//       const data1 = {
//         "fund": values.fund,
//       }
//       // console.log("date", values.fund);
//       const response = await adminUpdate(data1, id, SuperAdminToken);
//       console.log("respx", response);
//       if (response.message) {
//         setAlertMsg(true)
//         setAlertColor("success")
//         setTextAlert(response.message)
//         setTimeout(() => navigate('/superadmin/adminlist'), 1000)
//       }
//     },
//   })


//   const updateFund = async (rowId) => {
//     // console.log("SuperAdminToken", fund)
//     // console.log("id", id);
//     const data1 = {
//       "fund": abc,
//     }
//     // console.log("date", values.fund);

//     const response = await adminUpdate(data1, rowId, SuperAdminToken);
//     // console.log("respx", response);
//     if (response.message) {
//       setAlertMsg(true)
//       setAlertColor("success")
//       setTextAlert(response.message)
//       setTimeout(() => navigate('/superadmin/adminlist'), 1000)
//     }
//   }

//   const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

//   // console.log("fund", values);

//   return (
//     <>
//       {/* {/ <Alert severity="success" className={alertMsg ? "d-block": "d-none"}>{alertMsg}</Alert> /} */}
//       <Page title="User">
//         <Container>
//           <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
//             <Typography variant="h4" gutterBottom>
//               Admin List
//             </Typography>
//             <Button style={{ color: '#fff' }} variant="contained" component={RouterLink} to="/superadmin/addadmin" startIcon={<Iconify icon="line-md:plus" />}>
//               Add Admin
//             </Button>
//           </Stack>
//           <Card>
//             <DataTable columns={columns} data={data} />
//           </Card>
//         </Container>


//         <FormikProvider value={formik}>
//           <Form noValidate onSubmit={handleSubmit}>
//             <Modal show={show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
//               <Modal.Header closeButton>
//                 <Modal.Title id="contained-modal-title-vcenter">Add Funds</Modal.Title>
//               </Modal.Header>
//               <Modal.Body>

//                 <Row className="mb-3 ms-3">
//                   <Form.Group as={Col} controlId="formGridCompanyContact">
//                     <TextField style={{ width: 400 }} label="Funds" type='number' color="secondary"
//                       // {...getFieldProps('fund')}
//                       onChange={(e) => setAbc(e.target.value)}
//                       value={fund}
//                       error={Boolean(touched.fund && errors.fund)}
//                       helperText={touched.fund && errors.fund} />
//                   </Form.Group>

//                   <Form.Group as={Col} controlId="formGridCompanyContact">
//                     <TextField style={{ width: 400 }} label="Funds" type='number' color="secondary" />
//                   </Form.Group>
//                 </Row>

//               </Modal.Body>
//               <Modal.Footer>

//                 <Button variant="primary" onClick={() => updateFund(rowId)} >
//                   Update
//                 </Button>
//               </Modal.Footer>
//             </Modal>

//           </Form>
//         </FormikProvider>
//       </Page>


//       {alertMsg &&
//         <AlertMessages
//           hideAlert={hideClose}
//           showAlert={alertMsg}
//           message={textAlert}
//           alertColor={alertColor}
//         />
//       }
//     </>

//   )
// }

// export default AdminList




import React, { useEffect, useState } from 'react'
import { Link as RouterLink, NavLink, useParams, useNavigate } from 'react-router-dom';
import { Card, Stack, Button, Container, Typography, TextField, Switch, } from '@mui/material';
import { Col, Form, Row } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
// import MUIDataTable from 'mui-datatables';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import { fDateTime } from '../../utils/formatTime';
import Iconify from '../../components/Iconify';
import AlertMessages from "../../utils/AlertMessages";
import DataTable from "../../utils/DataTable";
import { getAdmins, deleteAdminById, getAdminById, getStatus, adminUpdate } from '../../services';



const AdminList = () => {
  const navigate = useNavigate()

  const SuperAdminToken = JSON.parse(localStorage.getItem('superadmin')).accessToken;
  const SuperAdminId = JSON.parse(localStorage.getItem('superadmin')).id;

  const [data, setData] = useState([]);

  const [refresh, setrefresh] = useState(true)
  const [alertMsg, setAlertMsg] = useState(false)
  const [alertColor, setAlertColor] = useState("")
  const [textAlert, setTextAlert] = useState("")
  const [show, setShow] = useState('');
  const [fltername, setFiltername] = useState('');

  const [fund, setFund] = useState("")
  const [rowId, setRowId] = useState("")
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  // const handleClose = () => setShow(false);


  const handleClickOpen = (id) => {
    setRowId(id)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const hideClose = () => {
    setAlertMsg(false)
  }

  // Get API For Get Packages Data
  const getPackages = async () => {
    const response = await getAdmins(SuperAdminToken)
    if (response) {
      setData(response);
    }
  }



  // Admin Status API

  const adminStatus = async (e, adminid) => {


    let status = '';
    if (e) {
      status = 1;
    } else {
      status = 0;
    }

    const data = {
      "admin_id": adminid,
      "status": status
    }
    const response = await getStatus(SuperAdminToken, data)
  }

  useEffect(() => {
    getPackages();

  }, [refresh]);


  //   // Delete Admin API

  const deleteItem = async (id) => {
    if (window.confirm("Do you want to delete this Client ?")) {
      const response = await deleteAdminById(SuperAdminToken, id)
      setrefresh(!refresh)

      if (response.message) {
        setAlertMsg(true)
        setAlertColor("success")
        setTextAlert(response.message)
      }
    }
  }


  //  add Fund 

  // console.log("data", data);


  const updateFund = async () => {
    const data1 = {
      "add_fund": fund,
    }
    const response = await adminUpdate(data1, rowId, SuperAdminToken);
    setrefresh(!refresh)
    if (response.message) {
      setAlertMsg(true)
      setAlertColor("success")
      setTextAlert("Fund Add Successfully")

      setOpen(false);

    }
  }


  const filterData = () => {

    //   const filterTable = data && data.filter((x) => {
    //     return x.fullname && x.fullname.includes(fltername) 
    //   })

    //   console.log("filterTable", filterTable);
  }

  // fltername














  const columns = [
    // {
    //   label: 'Sr .No',
    //   name: "srno",
    //   width: '150px !important',
    //   options: {
    //     customBodyRender: (value, tableMeta, rowData, updateValue) => {
    //       return tableMeta.rowIndex + 1

    //     },
    //   }
    // },



    {
      label: 'Sr .No',
      name: "id",
      width: '150px !important',
      options: {
        display: false,
      }
    },

    {
      label: 'FullName',
      name: "fullname",
      // sortable: true,
      width: '150px !important',
      options: {
        display: false,
      }
    },
    {
      label: 'Company Email',
      name: "company_email",
      sortable: true,
      width: '230px !important',
      options: {
        display: false,
      }
    },
    {
      label: ' Contact No.',
      name: 'personal_contact',
      sortable: true,
      width: '150px !important',
      options: {
        display: false,
      }
    },
    {
      label: 'Company Contact',
      name: "company_contact",
      sortable: true,
      width: '150px !important',
      options: {
        display: false,
      }
    },
    {
      label: 'Domain',
      name: "company_domain",
      sortable: true,
      width: '150px !important',
      options: {
        display: false,
      }
    },

    {
      label: 'Pan Verify',
      name: "pan",
      sortable: true,
      width: '150px !important',
      options: {
        display: false,
      }
    },
    {
      label: 'Adhar  Verify',
      name: "adhaar",
      sortable: true,
      width: '150px !important',
      options: {
        display: false,
      }
    },
    {
      label: 'Adhar Sign',
      name: "adhaar_sign",
      sortable: true,
      width: '150px !important',
      options: {
        display: false,
      }
    },

    {
      label: 'Address',
      name: "address",
      sortable: true,
      width: '150px !important',
      options: {
        display: false,
      }
    },
    {
      label: 'E Stamp',
      name: "client_stamp",
      sortable: true,
      width: '150px !important',
      options: {
        display: false,
      }
    },


    // ----------------------------------------
    {
      label: 'Date',
      name: `createdAt`,
      width: '150px !important',
      options: {
        customBodyRender: (value, tableMeta, rowData, updateValue) => {
          return (
            <p>
              {fDateTime(tableMeta.rowData[12])}
              {/* {  console.log("dddd" ,tableMeta.rowData[1] )} */}
            </p>
          )
        }
      },
    },
    {
      label: 'Company  Name',
      name: "username",
      // sortable: true,
      width: '150px !important',
    },

    {
      label: 'Email',
      name: "email",
      // sortable: true,
      width: '230px !important',
    },
    {
      label: 'Available Balance',
      name: "fund",
      // sortable: true,
      width: '150px !important',
    },
    {
      label: 'Package ID',
      name: "package_id",
      width: '160px !important',
      options: {
        display: false,
      }
    },

    {
      label: 'Status',
      name: "status",
      // sortable: true,
      width: '230px !important',
      options: {
        customBodyRender: (value, tableMeta, rowData, updateValue) => {
          return (
            <>
              <FormGroup>
                <FormControlLabel control={<Switch defaultChecked={tableMeta.rowData[17] === 1} onChange={(e) => adminStatus(e.target.checked, tableMeta.rowData[1])} />} />
                {console.log("tableMeta", tableMeta.rowData)}

              </FormGroup>

            </>
          )
        }
      }
    },
    {
      label: 'Actions',
      name: 'action',
      options: {
        filter: false,
        width: 250,
        customBodyRender: (value, tableMeta, rowData, updateValue) => {
          return (
            <>
              <NavLink
                to={`/superadmin/transactionhistory/${tableMeta.rowData[1]}`}
                state={tableMeta.rowData}

              >
                <Tooltip title="Transaction History">
                  <Icon icon="icon-park-outline:transaction-order"
                    color="#A8006D"
                    className=''
                    width="22"
                    variant="primary"
                    data-toggle="tooltip"
                    data-placement="top"
                  />
                </Tooltip>
              </NavLink>

              <NavLink
                to={`/superadmin/viewdetails/${tableMeta.rowData[1]}`}
                state={tableMeta.rowData}
              >
                <Tooltip title="View Details">
                  <Icon icon="clarity:grid-view-line"
                    color="#E43F47"
                    className=''
                    width="22"
                    variant="primary"
                    data-toggle="tooltip"
                    data-placement="top"
                  />
                </Tooltip>
              </NavLink>

              <Tooltip title="Add Funds">
                <Icon icon="bx:money-withdraw"
                  color="#FF822F"
                  className=''
                  width="22"
                  variant="primary"
                  data-toggle="tooltip"
                  data-placement="top"
                  cursor="pointer"
                  onClick={() => handleClickOpen(tableMeta.rowData[1])}
                />
              </Tooltip>


              <NavLink
                to={`/superadmin/editadmin/${tableMeta.rowData[1]}`}
                state={tableMeta.rowData}
              >
                <Tooltip title="Edit">
                  <Icon icon="akar-icons:edit"
                    color="#6BAA2C"
                    className=''
                    width="22"
                    variant="primary"
                    data-toggle="tooltip"
                    data-placement="top"
                  />
                </Tooltip>
              </NavLink>

              <Tooltip title="Delete">
                <Icon icon="ant-design:delete-outlined"
                  color="CD2B2E"
                  width="22"
                  data-toggle="tooltip"
                  data-placement="top"
                  cursor="pointer"
                  onClick={() => deleteItem(tableMeta.rowData[1])}
                />
              </Tooltip>
            </>
          )
        }
      }
    }
  ]


  return (
    // <ThemeProvider theme={theme} >


    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Company List
        </Typography>



        <Button style={{ color: '#fff' }} variant="contained" component={RouterLink} to="/superadmin/addadmin" startIcon={<Iconify icon="line-md:plus" />}>
          Add Company
        </Button>
      </Stack>

      <Card>
        {/* 
      <Form.Group as={Col} controlId="formGridPersonalContact">
        <TextField style={{ width: 400 }} label="filter" type='text' color="secondary"
          onChange={(e) => setFiltername(e.target.value)}
        />
      </Form.Group>
      <Button onClick={() => filterData()}>Submit</Button> */}

        <DataTable
          columns={columns}
          data={data}
        />

      </Card>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="addfund"
        >
          <DialogTitle id="alert-dialog-title">
            Add Fund To Comapny
          </DialogTitle>
          <DialogContent>
            <Form.Group as={Col} controlId="formGridPersonalContact">
              <TextField style={{ width: 400 }} label="Add Fund" type='number' color="secondary" onChange={(e) => setFund(e.target.value)} />
            </Form.Group>

          </DialogContent>
          <DialogActions>
            <Button onClick={() => updateFund()}>Submit</Button>

          </DialogActions>
        </Dialog>
      </div>


      {
        alertMsg && (
          <AlertMessages
            hideAlert={hideClose}
            showAlert={alertMsg}
            message={textAlert}
            alertColor={alertColor}
          />
        )
      }
    </Container>
  )
}

export default AdminList


