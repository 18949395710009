
// /* eslint no-var: 0 */
// import React, { useEffect, useState } from 'react'
// import { Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
// import { Card, Stack, Button, Container, Typography, TextField } from '@mui/material';
// import { Col, Form, Row, Modal } from 'react-bootstrap';
// import { Icon } from '@iconify/react';
// // Common DATA Table
// import DataTable from '../../../utils/DataTable'
// // import DataTable from '../../../utils'
// import Iconify from '../../../components/Iconify';
// import Page from '../../../components/Page';
// // API Function
// import { AdminfundHistory } from '../../../services';
// // Date Format
// import { fDate } from '../../../utils/formatTime';
// import AlertMessages from '../../../utils/AlertMessages';

// const FoundsHistory = () => {


//     const [fundData, setFundData] = useState([]);
//     // console.log("admin fund", fundData);

//     const SuperAdminToken = JSON.parse(localStorage.getItem('admin')).accessToken;
//     const AdminID = JSON.parse(localStorage.getItem('admin')).id;


//     const columns = [
//         {
//             name: 'User Details',
//             selector: row => row.userdetails,
//             sortable: true,
//             width: '230px !important',
//         },
//         {
//             name: 'Available Fund',
//             selector: row => row.available_fund,
//             sortable: true,
//             width: '170px !important',
//         },
//         {
//             name: 'Fund Added',
//             selector: row => row.fund_added,
//             sortable: true,
//             width: '230px !important',
//         },
//         {
//             name: 'Required Fund',
//             selector: row => row.required_fund,
//             sortable: true,
//             width: '230px !important',
//         },
//         {
//             name: 'Activities',
//             selector: row => row.action,
//             sortable: true,
//             width: '150px !important',
//         },
//         {
//             name: 'Actions',
//             // selector: row => ,
//             sortable: true,
//             width: '150px !important',
//         },
//     ];

//     const dataFund = [];
//     const getFundHistory = async () => {
//         const data = {
//             "adminid": AdminID
//         }
//         const response = await AdminfundHistory(data, SuperAdminToken)
//         if (response) {
//             response.forEach((fund) => {
//                 dataFund.push({
//                     action: fund.action,
//                     available_fund: fund.available_fund,
//                     fund_added: fund.fund_added,
//                     required_fund: fund.required_fund,
//                     userdetails: fund.userdetails.username
//                 })
//                 setFundData(dataFund)
//             })

//             // console.log("fundhistory", fundData);
//         }
//     }

//     useEffect(() => {
//         getFundHistory();
//     }, []);


//     return (
//         <>
//             <Page title="User">
//                 <Container>
//                     <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
//                         <Typography variant="h4" gutterBottom>
//                             Fund History
//                         </Typography>
//                     </Stack>
//                     <Card>
//                         <DataTable columns={columns} data={fundData} />
//                     </Card>
//                 </Container>
//             </Page>
//         </>
//     )
// }

// export default FoundsHistory




import React, { useEffect, useState } from 'react'
import { Link as RouterLink, NavLink, useParams } from 'react-router-dom';
import { Card, Stack, Button, Container, Typography, TextField, Switch, } from '@mui/material';
import { Icon } from '@iconify/react';
import Avatar from '@mui/material/Avatar';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
// import MUIDataTable from 'mui-datatables';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import { fDateTime } from '../../../utils/formatTime';

import Iconify from '../../../components/Iconify';
import AlertMessages from "../../../utils/AlertMessages";
import DataTable from "../../../utils/DataTable";
// import { getAdmins, deleteAdminById, getAdminById, getStatus } from '../../../services';
import { AdminfundHistory } from '../../../services'




const FoundsHistory = () => {

    const AdminToken = JSON.parse(localStorage.getItem('admin')).accessToken;
    const AdminID = JSON.parse(localStorage.getItem('admin')).id;

    const [data, setData] = useState([]);

    const [refresh, setrefresh] = useState(true)
    const [alertMsg, setAlertMsg] = useState(false)
    const [alertColor, setAlertColor] = useState("")
    const [textAlert, setTextAlert] = useState("")
    const [show, setShow] = useState('');

    const [fund, setFund] = useState("")
    const [rowId, setRowId] = useState("")
    const [checked, setChecked] = useState(false);

    const handleClose = () => setShow(false);


    const hideClose = () => {
        setAlertMsg(false)
    }

    // Get API For Get Packages Data
    const getPackages = async () => {
        const data = {
            "adminid": AdminID
        }

        const response = await AdminfundHistory(data, AdminToken)
        if (response) {
            console.log("respo", response);
            setData(response);
        }
    }



    // Admin Status API



    useEffect(() => {
        getPackages();
        // a()

    }, [refresh
    ]);









    const columns = [


        {
            label: 'Sr .No',
            name: "id",
            width: '150px !important',
            options: {
                display: false,
            }
        },
        {
            label: 'Date',
            name: `createdAt`,
            width: '150px !important',
            options: {
                customBodyRender: (value, tableMeta, rowData, updateValue) => {
                    return (
                        <p>
                             {fDateTime(tableMeta.rowData[2]) }            
                        </p>
                    )
                }
            },
        },
        {
            label: 'Credit',
            name: "fund_added",
            sortable: true,
            width: '230px !important',
        },
        {
            label: 'Available Balance',
            name: "required_fund",
            sortable: true,
            width: '150px !important',
            // options: {
            //     customBodyRender: (value, tableMeta, rowData, updateValue) => {
            //         return (
            //             <p>
            //                 {/* {tableMeta.rowData[3] === '0' ? tableMeta.rowData[3] : tableMeta.rowData[3]} */}
            //                  {fDateTime(tableMeta.rowData[2]) }            
            //                  {console.log("dddd" , tableMeta.rowData[3])}
            //             </p>
            //         )
            //     }
            // },
        },
        // {
        //     label: 'Actions',
        //     name: 'action',
        //     options: {
        //         filter: false,
        //         sort: false,
        //         width: 200,
        //         customBodyRender: (value, tableMeta, rowData, updateValue) => {
        //             return (
        //                 <>
        //                     {console.log("tableMeta", tableMeta.rowData[1])}
        //                     <Tooltip title="get Signed Document">
        //                         <Icon icon="akar-icons:download"
        //                             color="#E43F47"
        //                             className='mx-2'
        //                             width="22"
        //                             variant="primary"
        //                             data-toggle="tooltip"
        //                             data-placement="top"
        //                         />
        //                     </Tooltip>
        //                     <NavLink
        //                         to={`/admin/editclient/${tableMeta.rowData[1]}}`}
        //                         state={tableMeta.rowData}
        //                     >
        //                         <Tooltip title="View Details">
        //                             <Icon icon="clarity:grid-view-line"
        //                                 color="#6BAA2C"
        //                                 className='mx-2'
        //                                 width="22"
        //                                 variant="primary"
        //                                 data-toggle="tooltip"
        //                                 data-placement="top"
        //                             />
        //                         </Tooltip>
        //                     </NavLink>

        //                     <Tooltip title="Delete">
        //                         <Icon icon="ant-design:delete-outlined"
        //                             color="CD2B2E"
        //                             width="22"
        //                             data-toggle="tooltip"
        //                             data-placement="top"
        //                             cursor="pointer"
        //                             onClick={() => deleteItem(tableMeta.rowData[1])}
        //                         />
        //                     </Tooltip>
        //                 </>
        //             )
        //         }
        //     }
        // }
    ]


    return (
        // <ThemeProvider theme={theme} >


        <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                   Fund History
                </Typography>
                <Button style={{ color: '#fff' }} variant="contained" component={RouterLink} to="/admin/addclient" startIcon={<Iconify icon="line-md:plus" />}>
                    Add Client
                </Button>
            </Stack>
            <DataTable
                columns={columns}
                data={data}
            />




            {
                alertMsg && (
                    <AlertMessages
                        hideAlert={hideClose}
                        showAlert={alertMsg}
                        message={textAlert}
                        alertColor={alertColor}
                    />
                )
            }
        </Container>
    )
}

export default FoundsHistory


