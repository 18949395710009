// import React, { useEffect, useState } from 'react'
// import { Link as RouterLink, NavLink, useParams } from 'react-router-dom';
// import { Card, Stack, Button, Container, Typography } from '@mui/material';
// import { Icon } from '@iconify/react';
// // Common DATA Table
// import Tooltip from '@mui/material/Tooltip';
// import DataTable from '../../../utils/DataTable'
// import Iconify from '../../../components/Iconify';
// import Page from '../../../components/Page';
// import Pricing from '../../web-landing-page/Pricing';
// import AlertMessages from '../../../utils/AlertMessages';
// // API Function
// import { getAllPackages, deletePackage } from '../../../services';

// function Packages() {
//   const [data, setData] = useState([]);
//   const [refresh, setrefresh] = useState(true)
//   const [alertMsg, setAlertMsg] = useState(false)
//   const [alertColor, setAlertColor] = useState("")
//   const [textAlert, setTextAlert] = useState("")


//   const SuperAdminToken = JSON.parse(localStorage.getItem('superadmin')).accessToken;

//   const columns = [
//     {
//       name: 'Name',
//       selector: row => row.name,
//       sortable: true,
//       // width: '150px !important',
//     },
//     {
//       name: 'Price',
//       selector: row => row.price,
//       sortable: true,
//       // width: '150px !important',
//     },
//     {
//       name: 'Package Validity',
//       selector: row => row.package_validity,
//       sortable: true,
//       width: '170px !important',
//     },
//     {
//       name: 'Package D/M/Y',
//       selector: row => row.package_day_month,
//       sortable: true,
//       width: '160px !important',
//     },
//     {
//       name: 'Aadhar Sign Price',
//       selector: row => row.adhar_sign_price,
//       sortable: true,
//       width: '180px !important',
//     },
//     {
//       name: 'Aadhar verify price',
//       selector: row => row.adhar_verify_price,
//       sortable: true,
//       width: '190px !important',
//     },
//     {
//       name: 'PAN Verify Price',
//       selector: row => row.pan_verify_price,
//       sortable: true,
//       width: '170px !important',
//     },
//     {
//       name: 'Package Details',
//       selector: row => row.package_details,
//       sortable: true,
//       width: '160px !important',
//     },
//     {
//       name: "ACTIONS",
//       width: '100px !important',
//       selector: (row) => (
//         <>
//           {/* {console.log("data",row)} */}
//           <NavLink
//             to={`/superadmin/editpackages/${row.id}`}
//             state={row}
//           >
//             <Tooltip title="Edit">
//               <Icon icon="akar-icons:edit"
//                 color="#6BAA2C"
//                 className='mx-2'
//                 width="22"
//                 variant="primary"
//               />
//             </Tooltip>
//           </NavLink>

//           <Tooltip title="Delete">
//             <Icon icon="ant-design:delete-outlined"
//               color="CD2B2E"
//               width="22"
//               data-toggle="tooltip"
//               data-placement="top"
//               title="Delete"
//               cursor="pointer"
//               onClick={() => deleteItem(row.id)}
//             />
//           </Tooltip>
//         </>
//       ),
//     },
//   ];


//   // Get API For Get Packages Data

//   const getPackages = async () => {
//     const response = await getAllPackages(SuperAdminToken)
//     if (response) {
//       //  console.log("respo",response);
//       setData(response);
//     }
//   }



//   const deleteItem = async (id) => {
//     if (window.confirm("Do you want to delete this Client ?")) {
//       const response = await deletePackage(id, SuperAdminToken)
//       setrefresh(!refresh)

//       if (response.message) {
//         setAlertMsg(true)
//         setAlertColor("success")
//         setTextAlert(response.message)
//       }
//     }

//   }

//   useEffect(() => {
//     getPackages();
//   }, [refresh]);

//   const hideClose = () => {
//     setAlertMsg(false)
//   }


//   return (
//     <>
//       <Page title="Packages">
//         <Container>
//           <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
//             <Typography variant="h4" gutterBottom>
//               Packages
//             </Typography>
//             <Button style={{ color: '#fff' }} variant="contained" component={RouterLink} to="/superadmin/addpackages" startIcon={<Iconify icon="line-md:plus" />}>
//               Add Package
//             </Button>
//           </Stack>
//           <Card>
//             <DataTable columns={columns} data={data} />
//           </Card>
//         </Container>
//       </Page>
//       {alertMsg &&
//         <AlertMessages
//           hideAlert={hideClose}
//           showAlert={alertMsg}
//           message={textAlert}
//           alertColor={alertColor}
//         />
//       }
//     </>
//   )
// }

// export default Packages





import React, { useEffect, useState } from 'react'
import { Link as RouterLink, NavLink, useParams } from 'react-router-dom';
import { Card, Table, Stack, Avatar, Button, Checkbox, TableRow, Alert, TableBody, TableCell, Container, Typography, Tooltip, TableContainer, TablePagination, } from '@mui/material';
import { Icon } from '@iconify/react';
// dialog Box

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


import DataTable from "../../../utils/DataTable";
import Iconify from '../../../components/Iconify';
import { getAllPackages, deletePackage, editPackage } from '../../../services';
import AlertMessages from '../../../utils/AlertMessages';

const Packages = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [dialogData, setDialogData] = useState([]);
  // const [dialogData, setDialogData] = useState("");
  const [refresh, setrefresh] = useState(true)
  const [alertMsg, setAlertMsg] = useState(false)
  const [alertColor, setAlertColor] = useState("")
  const [textAlert, setTextAlert] = useState("")

  const SuperAdminId = JSON.parse(localStorage.getItem('superadmin')).id;
  const SuperAdminToken = JSON.parse(localStorage.getItem('superadmin')).accessToken;

  // dialogbox

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  console.log("dialogData", dialogData);

  const handleClickOpen = async (data) => {
    setOpen(true);
    setDialogData(data)
  }



  const handleClose = () => {
    setOpen(false);
  };

  // dialogbox

  const hideClose = () => {
    setAlertMsg(false)
  }


  const getPackagess = async () => {
    const response = await getAllPackages(SuperAdminToken)
    console.log("response", response.currently_assigne);
    if (response) {
      setData(response);
      // setNoDelate(response.currently_assigne);
    }
  }



  const deleteItem = async (id) => {

    if (window.confirm("Do you want to delete this Client ?")) {
      const response = await deletePackage(id, SuperAdminToken)
      setrefresh(!refresh)

      if (response.message) {
        setAlertMsg(true)
        setAlertColor("success")
        setTextAlert(response.message)
      }
    }

  }


  useEffect(() => {
    getPackagess();

  }, [refresh]);




  const columns = [
    {
      label: 'Sr .No',
      name: "id",
      width: '150px !important',
      options: {
        display: false,
      }
    },

    {
      label: 'Name',
      name: "name",
      width: '150px !important',
    },
    {
      label: 'Recharge Limit',
      name: "price",
      width: '150px !important',
      textAlign: "center"
    },
    {
      label: 'Package Validity',
      name: "package_validity",
      width: '170px !important',
      textAlign: "center",
      options: {
        filter: false,
        sort: false,
        width: 200,
        customBodyRender: (value, tableMeta, rowData, updateValue) => {
          return (
            <>
              {value} {tableMeta.rowData[5]}
            </>
          )
        }
      },
    },
    {
      label: 'Package D/M/Y',
      name: "package_day_month",
      width: '160px !important',
      options: {
        display: false,
      }
    },
    {
      label: 'Aadhar Sign Price',
      name: "adhar_sign_price",
      width: '180px !important',
      options: {
        display: false,
      }
    },
    {
      label: 'Aadhar verify price',
      name: "adhar_verify_price",
      width: '190px !important',
      options: {
        display: false,
      }
    },
    {
      label: 'PAN Verify Price',
      name: "pan_verify_price",
      width: '370px !important',
      options: {
        display: false,
      }
    },
    {
      label: 'Package Details',
      name: "package_details",
      width: '160px !important',
    },

    {
      label: 'Package Details',
      name: "currently_assigne",
      width: '160px !important',
      options: {
        display: false
      }
    },

    {
      label: 'Actions',
      name: 'action',
      options: {
        filter: false,
        sort: false,
        width: 200,
        customBodyRender: (value, tableMeta, rowData, updateValue) => {
          return (
            <>
              {console.log("id", tableMeta.rowData)}
              <div className='d-flex'>
                <Tooltip title="View Details" >
                  <Icon icon="akar-icons:eye" height="25" onClick={() => handleClickOpen(tableMeta.rowData)} />
                </Tooltip>
                <NavLink to={`/superadmin/editpackages/${tableMeta.rowData[1]}`} state={tableMeta.rowData}>
                  <Tooltip title="Edit" className='mx-2' onClick={() => console.log(tableMeta.rowIndex + 1)}>
                    <Icon icon="ant-design:edit-filled" height="25" />
                  </Tooltip>
                </NavLink>
                <Tooltip title="Delete" className={ tableMeta.rowData[10] === 1 ?  "d-none" : "d-block" }>
                  <Icon icon="ant-design:delete-filled" height="25" onClick={() => deleteItem(tableMeta.rowData[1])} />
                </Tooltip>
              </div>
            </>
          );
        }
      }
    },




  ];



  return (

    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Packages
          </Typography>
          <Button style={{ color: '#fff' }} variant="contained" component={RouterLink} to="/superadmin/addpackages" startIcon={<Iconify icon="line-md:plus" />}>
            Add Package
          </Button>
        </Stack>

        <DataTable
          columns={columns}
          data={data}

        />

        {/* dialog Box  */}
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          maxWidth='xl'
        >

          <DialogContent>
            <DialogContentText>
              <h4 className="text-center" style={{ color: "#212B36 ", fontWeight: "bolder", fontSize: "36px" }}>
                Package Details
              </h4>
              <div className="w-100 row mb-4 mt-4 mx-auto">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Package Name</th>
                      <th>Recharge</th>
                      <th>Package Validity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{dialogData[2]} </td>
                      <td>{dialogData[3]} </td>
                      <td>{dialogData[4]} {dialogData[5]} </td>
                    </tr>

                  </tbody>
                </table>
                {/* <div className="col-md-4">
                  <Typography variant="h6" gutterBottom>
                    Package Name
                  </Typography>
                  <p>{dialogData[2]} </p>
                </div>
                <div className="col-md-4">
                  <Typography variant="h6" gutterBottom>
                    Recharge 
                  </Typography>
                  <p>{dialogData[3]} </p>
                </div>
                {/* <div className="col-md-4">
                    <Typography variant="h6" gutterBottom>
                   Package Days/Month/year
                    </Typography>
                    <p>hello</p>
                  </div> */}
                {/* <div className="col-md-3">
                  <Typography variant="h6" gutterBottom>
                    Package Validity
                  </Typography>
                  <p>{dialogData[4]} {dialogData[5]} </p>
                </div> */}
              </div>
              <h4 className="text-center" style={{ color: "#212B36", fontWeight: "bolder", fontSize: "36px" }}>
                Price
              </h4>
              <div className="row mb-3 mt-4">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Aadhaar E-Sign </th>
                      <th> Aadhaar  verification</th>
                      <th> Pan Verification</th>
                      <th> Package Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{dialogData[6]} </td>
                      <td>{dialogData[7]} </td>
                      <td>{dialogData[8]}</td>
                      <td> {dialogData[9]} </td>
                    </tr>

                  </tbody>
                </table>
              </div>
              {/*   <div className="row mb-3 mt-4">
                <div className="col-4">
                  <Typography variant="h6" gutterBottom>
                    Aadhaar Verification
                  </Typography>
                  <p>{dialogData[6] } </p>
                </div>
                <div className="col-4">
                  <Typography variant="h6" gutterBottom>
                    Pan verification
                  </Typography>
                  <p>{dialogData[7] } </p>
                </div>
                <div className="col-4">
                  <Typography variant="h6" gutterBottom>
                    E-sign
                  </Typography>
                  <p>{dialogData[8] } </p>
                </div>
                <div className="col-md-3">
                  <Typography variant="h6" gutterBottom>
                    Package Details
                  </Typography>
                  <p>{dialogData[9] }</p>
                </div>
                </div> */}

            </DialogContentText>
          </DialogContent>
          {/* <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Disagree
            </Button>
            <Button onClick={handleClose} autoFocus>
              Agree
            </Button>
          </DialogActions> */}
        </Dialog>

        {/* dialog box */}



        {alertMsg && (
          <AlertMessages
            hideAlert={hideClose}
            showAlert={alertMsg}
            message={textAlert}
            alertColor={alertColor}
          />
        )}

      </Container>

    </>
  )
}

export default Packages



