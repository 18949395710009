import React, { useEffect, useState } from "react";
import { Link as RouterLink, useParams, useLocation } from "react-router-dom";
import { Card, Stack, Button, Container, Typography } from "@mui/material";
import { Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Document } from 'react-pdf';
import Iconify from "../../components/Iconify";
import Page from "../../components/Page";
import AlertMessages from "../../utils/AlertMessages";
import * as Common from "../../utils/CommonMessage";
import { getUsersById } from "../../services";

const EditClient = () => {
  const { id } = useParams();
  const location = useLocation();
  const [dataClient, setDataClient] = useState([]);
  


// console.log("location" ,location);

  const getParticularClient = async () => {
    const response = await getUsersById(id);
    console.log("editclient" , response);
    if (response) {
      setDataClient(response.data);
    }
  };

  useEffect(() => {
    getParticularClient();
  }, []);

  function toBase64(arr) {
    console.log("sdsds" ,arr);
    // arr = new Uint8Array(arr) 
    return btoa(
      arr.reduce((data, byte) => data + String.fromCharCode(byte), "")
    );
  }

  /* eslint-disable camelcase */
  const {
    username,
    fullname,
    email,
    personal_contact,
    client_stamp,
    adhaar,
    adhaar_sign,
    pan,
    data,
    document

  } = dataClient;

  // console.log("document" ,document);
  //   console.log("Datata", dataClient);
  return (
    <Page title="User">
      {dataClient && (
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              User Detail View
            </Typography>
            <Button
              style={{ color: "#fff" }}
              variant="contained"
              component={RouterLink}
              to="/admin/clientlist"
              startIcon={<Iconify icon="line-md:arrow-left" />}
            >
              Back
            </Button>
          </Stack>
          <Card>
            <Form>
              <div className="detail-view mx-5 my-4">
                <div className="row mb-3">
                  <Typography variant="h4" gutterBottom className="mb-4">                  </Typography>
                  <div className="col-md-6">
                    <Typography variant="h6" gutterBottom>
                      Document
                    </Typography>
                    {/* <p><img src='https://www.freeiconspng.com/thumbs/document-icon/document-icon-19.png' alt="..." style={{ width: '80px' }} /></p> */}
                    <p>

                    {/* <div className=" border my-2" style={{ height: "150px", width: '402px', borderRadius: '8px', position: "relative" }}>
                      <img src={typeof image === "string" ? `data:image/png;base64,${image}` : URL.createObjectURL(image)} alt="zzz" style={{ height: "148px", width: '100%', borderRadius: '8px', position: "absolute" }}
                      />
                    </div> */}


                      <iframe
                      // src={URL.createObjectURL(document)}
                      // src="/images/e_sign-H.R. Policy...pdf"
                      src={`/images/e_sign-${document}`}
                        // src={`data:application/pdf;base64 ,${data}`}
                        title="myFrame"
                        width="450"
                        height="500"
                      />

{/* <Document file="/images/e_sign-H.R. Policy...pdf" /> */}
        {/* <Page pageNumber={pageNumber} /> */}
                      {/* <img    src="/images/e_sign-H.R. Policy...pdf"    alt="ddd"/> */}

                    </p>
                  </div>
                  <div className="col-md-6">
                    <Typography variant="h6" gutterBottom>
                      Verification
                    </Typography>
                    <ul>
                      {adhaar === 1 && <li>Aaddhar Varify</li>}
                      {pan === 1 && <li>Pan Varify</li>}
                      {adhaar_sign === 1 && <li>Aadhaar Sign</li>}
                    </ul>
                    {client_stamp && (
                      <>
                        <Typography variant="h6" gutterBottom>
                          Stamp
                        </Typography>
                        <ul>
                          <li>{client_stamp}</li>
                        </ul>
                      </>
                    )}
                  </div>
                </div>

                <div className="row mb-3 mt-5">
                  <Typography variant="h4" gutterBottom className="mb-4">
                    User
                  </Typography>
                  <div className="col-md-6">
                    <Typography variant="h6" gutterBottom>
                      Full Name
                    </Typography>
                    <p>{fullname}</p>
                  </div>
                 
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <Typography variant="h6" gutterBottom>
                      Email
                    </Typography>
                    <p>{email}</p>
                  </div>
                  <div className="col-md-6">
                    <Typography variant="h6" gutterBottom>
                      Mobile Number
                    </Typography>
                    <p>{personal_contact}</p>
                  </div>
                </div>
                {/* <Button variant="outlined" className='btn-lg  my-2' type="submit">
                                    Update
                                </Button> */}
                                
              </div>
            </Form>
          </Card>
        </Container>
      )}
    </Page>
  );
};

export default EditClient;
