// import React, { useEffect, useState } from 'react'
// import { Link as RouterLink, NavLink, useParams } from 'react-router-dom';
// import { Card, Table, Stack, Avatar, Button, Checkbox, TableRow, Alert, TableBody, TableCell, Container, Typography, Tooltip, TableContainer, TablePagination, } from '@mui/material';

// import { Icon } from '@iconify/react';
// import DataTable from '../../utils/DataTable'
// import Iconify from '../../components/Iconify';
// import Page from '../../components/Page';
// import AlertMessages from '../../utils/AlertMessages';
// import { getUsers, deleteUsers } from '../../services'

// const ClientList = () => {

//     const [data, setData] = useState([]);
//     const [refresh, setRefresh] = useState(true)
//     const [alertMsg, setAlertMsg] = useState(false)
//     const [alertColor, setAlertColor] = useState("")
//     const [textAlert, setTextAlert] = useState("")

//     const { id } = useParams()

//     const AdminToken = JSON.parse(localStorage.getItem('admin')).accessToken;

//     const columns = [
//         {
//             name: 'FullName',
//             selector: row => row.fullname,
//             sortable: true,
//         },
//         {
//             name: 'UserName',
//             selector: row => row.username,
//             sortable: true,
//         },
//         {
//             name: 'Email',
//             selector: row => row.email,
//             sortable: true,
//         },
//         {
//             name: 'Mobile',
//             selector: row => row.personal_contact,
//             sortable: true,
//         },
//         // {
//         //     label: 'No',
//         //     name: "id",
//         //     sortable: true,
//         // },
//         // {
//         //     label: 'FullName',
//         //     name: "fullname",
//         //     sortable: true,
//         // },
//         // {
//         //     label: 'UserName',
//         //     name: "username",
//         //     sortable: true,
//         // },
//         // {
//         //     label: 'Email',
//         //     name: "email",
//         //     sortable: true,
//         // },
//         // {
//         //     label: 'Mobile',
//         //     name: "personal_contact",
//         //     sortable: true,
//         // },
//         {
//             // label: "actions",
//             width: '150px !important',
//             name: "actions",
//             selector: (row) => (
//                 <>
//                          <Tooltip title="Get Signed Document">
//                             <Icon icon="akar-icons:download"
//                                 color="#E43F47"
//                                 className='mx-2'
//                                 width="22"
//                                 variant="primary"
//                                 data-toggle="tooltip"
//                                 data-placement="top"
//                                 // title="Edit Client"
//                                 onClick={()=>getSignedDocument(row)}
//                             />
//                         </Tooltip>
//                     <NavLink
//                         to={`/admin/editclient/${row.id}`}
//                         state={row}
//                     >
//                         <Tooltip title="View Details">
//                             <Icon icon="clarity:grid-view-line"
//                                 color="#E43F47"
//                                 className='mx-2'
//                                 width="22"
//                                 variant="primary"
//                                 data-toggle="tooltip"
//                                 data-placement="top"
//                                 // title="Edit Client"
//                             />
//                         </Tooltip>
//                     </NavLink>

//                     <Tooltip title="Delete">
//                         <Icon icon="ant-design:delete-outlined"
//                             color="CD2B2E"
//                             width="22"
//                             data-toggle="tooltip"
//                             data-placement="top"
//                             cursor="pointer"
//                             // title="Delete"
//                             onClick={() => deletePerticularUsers(row.id)}
//                         />
//                     </Tooltip>
//                 </>
//             )
//         }
//     ];

//     const data1 = [];

//     const getAllUsers = async () => {
//         const response = await getUsers(AdminToken)
//         if (response) {

//             response.forEach((item) => {
//                 data1.push({
//                     id: item.id,
//                     fullname: item.fullname,
//                     username: item.username,
//                     email: item.email,
//                     personal_contact: item.personal_contact,
//                     signeddocument: item.signeddocument
//                     // actions: <Link  className= 'btn btn-primary' > Edit </Link > & nbsp <a>delete </a>
//                 })
//             })
//             console.log("ffffff", data1);
//             setData(data1);
//         }
//     }

//     const getSignedDocument=(data)=>{
//        //     }

//     useEffect(() => {
//         getAllUsers();
//     }, [refresh]);


//     // Delete Users

//     const deletePerticularUsers = async (id) => {

//         if (window.confirm("Do you want to delete this Client ?")) {
//             const response = await deleteUsers(AdminToken, id)
//             setRefresh(!refresh)
//             if (response.message) {
//                 setAlertMsg(true)
//                 setAlertColor("success")
//                 setTextAlert(response.message)
//             }
//         }
//     }
//     // setTimeout(() => {
//     //     setAlertMsg(alertMsg)
//     // }, 10000);

//     const hideClose = () => {
//         setAlertMsg(false)
//     }

//     return (
//         <>
//             <Page title="User">
//                 <Container>
//                     <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
//                         <Typography variant="h4" gutterBottom>
//                             Client List
//                         </Typography>
//                         <Button style={{ color: '#fff' }} variant="contained" component={RouterLink} to="/admin/addclient" startIcon={<Iconify icon="line-md:plus" />}>
//                             Add Client
//                         </Button>
//                     </Stack>
//                     <Card>
//                         <DataTable columns={columns} data={data} />
//                     </Card>
//                 </Container>
//             </Page>
//             {alertMsg &&
//                 <AlertMessages
//                     hideAlert={hideClose}
//                     showAlert={alertMsg}
//                     message={textAlert}
//                     alertColor={alertColor}
//                 />
//             }
//         </>
//     )
// }

// export default ClientList



import React, { useEffect, useState } from 'react'
import { Link as RouterLink, NavLink, useParams } from 'react-router-dom';
import { Card, Stack, Button, Container, Typography, TextField, Switch, } from '@mui/material';
import { Icon } from '@iconify/react';
import Tooltip from '@mui/material/Tooltip';
import Iconify from '../../components/Iconify';
import AlertMessages from "../../utils/AlertMessages";
import DataTable from "../../utils/DataTable";
// import { getAdmins, deleteAdminById, getAdminById, getStatus } from '../../services';
import { getUsers, deleteUsers, getSignedDocument } from '../../services'




const ClientList = () => {

    const AdminToken = JSON.parse(localStorage.getItem('admin')).accessToken;

    const [data, setData] = useState([]);

    // console.log("data.client_id", data.fullname);
    const [refresh, setrefresh] = useState(true)
    const [alertMsg, setAlertMsg] = useState(false)
    const [alertColor, setAlertColor] = useState("")
    const [textAlert, setTextAlert] = useState("")
    const [show, setShow] = useState('');

    const [fund, setFund] = useState("")
    const [rowId, setRowId] = useState("")
    const [checked, setChecked] = useState(false);




    const handleClose = () => setShow(false);


    const hideClose = () => {
        setAlertMsg(false)
    }

    // Get API For Get Packages Data
    const getPackages = async () => {
        const response = await getUsers(AdminToken)
        if (response) {
            setData(response);
        }
    }



    // Admin Status API



    useEffect(() => {
        getPackages();
        // a()

    }, [refresh
    ]);





    //   // Delete Admin API

    const deleteItem = async (id) => {
        if (window.confirm("Do you want to delete this Client ?")) {
            const response = await deleteUsers(AdminToken, id)
            setrefresh(!refresh)

            if (response.message) {
                setAlertMsg(true)
                setAlertColor("success")
                setTextAlert(response.message)
            }
        }
    }


    // Get Signed Document


    const SignedDocument = async (signDoc, ss, name, ClientID, status, veiaotp, digndoc) => {

        console.log("dddd" ,document );

        if (status === 1) {
            if (veiaotp === 1) {
                const downloadLink = document.createElement("a")
                const fileName = `${name}-Signed_document.pdf`;;
                // downloadLink.href = signDoc;
                downloadLink.href = `../../../public/signeddocument/e_sign-${digndoc}`;
                downloadLink.download = fileName;
                downloadLink.target = '_blank'
                downloadLink.click();
            }
            // else {

            const response = await getSignedDocument(ClientID)
            // console.log("response" ,response);
            if (response.message) {
                const downloadLink = document.createElement("a")
                const fileName = `${name}-Signed_document.pdf`;
                downloadLink.href = response.data.url;
                // downloadLink.download = fileName;
                downloadLink.target = '_blank'
                downloadLink.click();
            }
            // }


        } else {
            setAlertMsg(true);
            setAlertColor("error");
            setTextAlert(" Please Wait For While ");
        }

    }


    const GetKycDocument = (doc, name) => {
        if (doc !== '0') {
            // const docUrl = URL.createObjectURL( blob );
            const a = document.createElement("a");
            a.setAttribute("download", `kyc-${name}.pdf`);
            a.setAttribute("href", doc);
            document.body.appendChild(a);
            a.click();

            // const downloadLink = document.createElement("a")
            // const fileName = `kyc-${name}.pdf`;;
            // downloadLink.href = doc;
            // downloadLink.download = fileName;
            // downloadLink.target = '_blank'
            // downloadLink.click();
        }
        else {
            setAlertMsg(true);
            setAlertColor("error");
            setTextAlert(" Please Wait For Sign  ");
        }
    }



    const columns = [
        // {
        //     label: 'Sr .No',
        //     name: "srno",
        //     width: '150px !important',
        //     options: {
        //         customBodyRender: (value, tableMeta, rowData, updateValue) => {
        //             return tableMeta.rowIndex + 1

        //         },
        //     }
        // },
        {
            label: 'Sr .No',
            name: "id",
            width: '150px !important',
            // display: false,
            options: {
                customBodyRender: (value, tableMeta, rowData, updateValue) => {
                    return tableMeta.rowIndex + 1
                },
                display: false,
            }
        },

        {
            label: 'Name',
            name: "fullname",
            sortable: true,
            width: '150px !important',
        },
        {
            label: 'Email',
            name: "email",
            sortable: true,
            width: '230px !important',
        },
        {
            label: 'mobile No.',
            name: "personal_contact",
            sortable: true,
            width: '230px !important',
            options: {
                display: false,
            }
        },
        {
            label: 'signedDocument.',
            name: "signeddocument",
            sortable: true,
            width: '230px !important',
            options: {
                display: false,
            }
        },
        {
            label: 'otpbsed',
            name: "otpbased",
            sortable: true,
            width: '230px !important',
            options: {
                display: false,
                customBodyRender: (value, tableMeta, rowData, updateValue) => {
                    return (
                        <>
                        </>
                    )
                }
            }
        },
        {
            label: 'Sign Status',
            name: "sign_status",
            sortable: true,
            width: '150px !important',
            options: {
                filter: false,
                sort: false,
                width: 200,
                customBodyRender: (value, tableMeta, rowData, updateValue) => {
                    return (

                        <p>{tableMeta.rowData[7] === 1 ? 'Sign-Done' : 'Sign-Pending'}</p>
                    )
                }
            }

        },
        {
            label: 'Sign Status',
            name: "client_id",
            sortable: true,
            width: '150px !important',
            options: {
                display: false,
            }
        },
        {
            label: 'Actions',
            name: 'action',
            options: {
                filter: false,
                sort: false,
                width: 200,
                customBodyRender: (value, tableMeta, rowData, updateValue) => {
                    return (
                        <>

                            <Tooltip title="Get Kyc Document">
                                <Icon icon="ant-design:cloud-download-outlined"
                                    color="#E43F47"
                                    className='mx-2'
                                    width="22"
                                    variant="primary"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    onClick={() => GetKycDocument(tableMeta.rowData[10], tableMeta.rowData[2])}
                                />
                            </Tooltip>
                            <Tooltip title="Get Signed Document">
                                <Icon icon="akar-icons:download"
                                    color="#E43F47"
                                    className='mx-2'
                                    width="22"
                                    variant="primary"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    onClick={() => SignedDocument(tableMeta.rowData[5], tableMeta.rowData[1], tableMeta.rowData[2], tableMeta.rowData[8], tableMeta.rowData[7], tableMeta.rowData[7] , tableMeta.rowData[[11]]) }
                                />
                            </Tooltip>
                            <NavLink
                                to={`/admin/editclient/${tableMeta.rowData[1]}}`}
                                state={tableMeta.rowData}
                            >

                                <Tooltip title="View Details">
                                    <Icon icon="clarity:grid-view-line"
                                        color="#6BAA2C"
                                        className='mx-2'
                                        width="22"
                                        variant="primary"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                    />
                                </Tooltip>
                            </NavLink>

                            <Tooltip title="Delete">
                                <Icon icon="ant-design:delete-outlined"
                                    color="CD2B2E"
                                    width="22"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    cursor="pointer"
                                    onClick={() => deleteItem(tableMeta.rowData[1])}
                                />
                            </Tooltip>
                        </>
                    )
                }
            }
        },
        {
            label: 'kycDocument',
            name: "kycdocument",
            sortable: true,
            width: '150px !important',
            options: {
                display: false,
                //        customBodyRender: (value, tableMeta, rowData, updateValue) => {
                //         return (
                //             <>
                //     {        console.log("kyc document " ,tableMeta.rowData[10] ) }
                //               </>)}
                // }
            }
        },
        {
            label: 'document',
            name: "document",
            sortable: true,
            width: '150px !important',
            options: {
                display: false,
                       customBodyRender: (value, tableMeta, rowData, updateValue) => {
                        return (
                            <>
                    {        console.log("kyc document " ,tableMeta.rowData) }
                              </>)}
                // }
            }
        }
    ]


    return (
        // <ThemeProvider theme={theme} >


        <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    Client List
                </Typography>
                <Button style={{ color: '#fff' }} variant="contained" component={RouterLink} to="/admin/addclient" startIcon={<Iconify icon="line-md:plus" />}>
                    Add Client
                </Button>
            </Stack>
            <DataTable
                columns={columns}
                data={data}
            />




            {
                alertMsg && (
                    <AlertMessages
                        hideAlert={hideClose}
                        showAlert={alertMsg}
                        message={textAlert}
                        alertColor={alertColor}
                    />
                )
            }
        </Container>
    )
}

export default ClientList


